import {
  Box,
  Button,
  CircularProgress,
  Grid,
  InputAdornment,
  makeStyles,
  Paper,
  TextField,
  Typography,
  Tooltip,
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { actions } from "ipmo_v_dbinder";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { PatientList } from "./patientList";
import { RegistrationForm } from "./registrationForm";
const useStyles = makeStyles((theme) => ({
  content: {
    width: "100%",
    height: "100%",
    padding: "16px",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "8px 16px",
    background: theme.palette.background.table,
    height: "54px",
    borderBottom: " 1px solid #e0e0e0",
    // position: "sticky",
  },
  SearchContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "4px 8px",
    // background: theme.palette.background.table,
    height: "54px",
  },
  body: {
    padding: "8px 16px",
    background: theme.palette.background.gray,
    height: "calc(100% - 54px)",
  },
  inputBase: {
    "& fieldset": {
      border: "none",
    },
  },
}));
export const VisitRegistration = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const Person = useSelector(
    (state) => state?.signInReducerApiSlice.pesronloggedin
  );
  const organizationSlice = useSelector(
    (state) => state?.personSlice.orgname?.data
  );
  const [patientList, setPatientList] = React.useState();
  const [showForm, setShowForm] = React.useState(false);
  const [showAddNew, setShowAddNew] = React.useState(false);
  const [edit, setEdit] = React.useState({
    isEdit: false,
    editData: null,
  });
  const [transfer, setTransfer] = React.useState({
    isTransfer: false,
    transferData: null,
  });
  const [searchValue, setSearchValue] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const onHandleChange = (e) => {
    setSearchValue(e.target.value);
    // console.log("Person", searchValue, e.target.value);
    if (e.target.value.length === 0) {
      onSearchClicked(e.target.value);
    }
    // console.log("Person", Person);
  };

  const [listCheck, setListCheck] = React.useState([]);

  const onEnterClicked = (e) => {
    if (e.key === "Enter") {
      onSearchClicked();
    }
  };
  const patientCheckInFunction = () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify({
        db_name: process.env.REACT_APP_DB,
        entity: process.env.REACT_APP_ENTITY_PATIENTCHECKIN,
        filter: `${process.env.REACT_APP_ENTITY_PATIENTCHECKIN}.activestatus==true`,
        return_fields: `${process.env.REACT_APP_ENTITY_PATIENTCHECKIN}.BedCode`,
      }),
      redirect: "follow",
    };
    fetch(process.env.REACT_APP_ARANGO_URL_READ, requestOptions)
      .then((result) => result.json())
      .then((response) => setListCheck(response.result))
      .catch((err) => console.error(err));
  };
  const onSearchClicked = async (val) => {
    //debugger;
    if (val !== "" && searchValue?.length !== 0) {
      // var myHeaders = new Headers();
      // myHeaders.append("Content-Type", "application/json");
      // var raw = {
      //   db_name: "ipmo",
      //   entity: "Patient",
      //   filter: `LOWER(Patient.PatientFName) LIKE '%${searchValue}%' || LOWER(Patient.PatientMRN) LIKE '${searchValue}' || LOWER(Patient.PatientMobNumber) LIKE '${searchValue}' && Patient.activestatus==true`,
      //   return_fields:
      //     "MERGE(Patient,{PatientMapping:FIRST(FOR PatientCheckIn IN PatientCheckIn FILTER PatientCheckIn.PatientCode == Patient._id &&  PatientCheckIn.activestatus == true RETURN PatientCheckIn)})",
      // };
      // var requestOptions = {
      //   method: "POST",
      //   headers: myHeaders,
      //   body: JSON.stringify(raw),
      //   redirect: "follow",
      // };
      // fetch(
      //   `${process.env.REACT_APP_ARANGO_API}/api/read_documents`,
      //   requestOptions
      // )
      //   .then((result) => result.json())
      //   .then((response) => {
      //     console.log("onsearch", response?.result[0]);
      //     dataShow = response?.result[0]?.PatientMapping;
      // if (
      //   response?.result[0]?.PatientFName?.toLowerCase() ===
      //   searchValue?.toLowerCase()
      // ) {
      //   setCheckIn(response?.result[0]?.PatientMapping);
      //   setPatientList({
      //     ...patientList,
      //     ...response?.result[0]?.PatientMapping,
      //   });
      // } else {
      //   setCheckIn([]);
      //   setPatientList({
      //     ...patientList,
      //   });
      // }
      // });
      setLoading(true);

      let response = await dispatch(
        actions.SEARCH_PATIENT(searchValue?.toLowerCase())
      );
      if (response?.payload?.data.length > 0) {
        setEdit({
          isEdit: false,
          editData: null,
        });
        setTransfer({
          isTransfer: false,
          transferData: null,
        });
        setPatientList(response?.payload?.data);
        // setPatientList([temp]);
        setShowForm(false);
        setLoading(false);
        setShowAddNew(true);
      } else {
        setEdit({
          isEdit: false,
          editData: null,
        });
        setTransfer({
          isTransfer: false,
          transferData: null,
        });
        setLoading(false);
        setShowForm(true);
        setShowAddNew(false);
      }
    } else {
      setEdit({
        isEdit: false,
        editData: null,
      });
      setTransfer({
        isTransfer: false,
        transferData: null,
      });
      setShowAddNew(false);
      setShowForm(false);
      setPatientList();
      setSearchValue();
    }
    patientCheckInFunction();
  };
  const onAddNewClicked = () => {
    if (edit.isEdit) {
      setEdit({
        isEdit: false,
        editData: null,
      });
      setTransfer({
        isTransfer: false,
        transferData: null,
      });
      setLoading(false);
      setShowForm(true);
      setShowAddNew(false);
      setSearchValue("");
    } else {
      setShowForm(true);
      setSearchValue("");
      setShowAddNew(false);
    }
  };

  const FormCompleted = () => {
    setShowForm(false);
    onSearchClicked();
  };

  const isTransferCompleted = () => {
    setShowForm(false);
    //  onSearchClicked();
    setTransfer({
      isTransfer: false,
      transferData: null,
    });
    onSearchClicked();
  };
  // console.log("patientList", patientList);
  const editForm = (data) => {
    // console.log("maindata", data);
    if (!data.hasOwnProperty("PatientMapping")) {
      data = { ...data, org: organizationSlice?.[0]?.id };
    }
    setEdit({
      isEdit: true,
      editData: data,
    });
    setShowForm(true);
  };
  React.useLayoutEffect(() => {
    dispatch(actions.ORGNAME());
  }, [dispatch]);

  const transferForm = (data) => {
    if (!data.hasOwnProperty("PatientMapping")) {
      data = { ...data, org: organizationSlice?.[0]?.id };
    }
    setTransfer({
      isTransfer: true,
      transferData: data,
    });
    setShowForm(true);
  };
  return (
    <>
      <Paper
        style={{
          width: "100%",
          height: "100%",
          backgroundColor: "#F3F4F7",
          borderRadius: "8px",
          overflow: "hidden",
        }}
        elevation={2}
      >
        <Box className={classes.header}>
          <Typography variant="body1">{"Patient Registration"}</Typography>
        </Box>

        <Box className={classes.body}>
          <Grid container className={classes.SearchContainer}>
            <Grid
              item
              xs={12}
              sm={12}
              lg={8}
              style={{
                background: "#fff",
                padding: "8px",
                borderRadius: "8px",
                display: "flex",
              }}
            >
              <div
                style={{
                  cursor: (edit.isEdit || transfer?.isTransfer) && "pointer",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                onClick={() =>
                  (edit.isEdit || transfer?.isTransfer) && onSearchClicked()
                }
              >
                {edit.isEdit || transfer?.isTransfer ? (
                  <Tooltip title="Back" placement="left">
                    <ArrowBackIcon style={{ color: "grey" }} />
                  </Tooltip>
                ) : (
                  <SearchIcon style={{ color: "grey" }} />
                )}
              </div>

              <TextField
                id="outlined-basic"
                variant="outlined"
                fullWidth
                autoComplete="off"
                size="small"
                name="states"
                onChange={(e) => onHandleChange(e)}
                placeholder={
                  "Search Patient First Name or Last Name or MRN or Mobile Number"
                }
                className={classes.inputBase}
                value={searchValue}
                autoFocus
                onKeyPress={(e) => onEnterClicked(e)}
                //   style={{ background: "#fff", border: "0" }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment
                      position="end"
                      style={{ position: "relative", left: "12px" }}
                    >
                      <Button
                        size="small"
                        variant="contained"
                        color="primary"
                        auto
                        onClick={() => onSearchClicked()}
                      >
                        Search
                      </Button>
                      {showAddNew && (
                        <Button
                          size="small"
                          variant="contained"
                          color="primary"
                          style={{ marginLeft: "8px" }}
                          onClick={() => onAddNewClicked()}
                        >
                          + Add New
                        </Button>
                      )}
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Grid>
          {/* <Grid container> */}
          {/* <div> */}
          <Box
            style={{
              height: "calc(100% - 55px)",
              padding: "8px",
              overflow: "auto",
            }}
          >
            {loading ? (
              <div
                style={{
                  height: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <CircularProgress />
              </div>
            ) : showForm ? (
              <RegistrationForm
                isEdit={edit?.isEdit}
                isTransfer={transfer?.isTransfer}
                editData={
                  transfer?.isTransfer ? transfer?.transferData : edit?.editData
                }
                FormCompleted={FormCompleted}
                onTransferCompleted={isTransferCompleted}
                listCheck={listCheck}
                Person={Person?.data}
                showAddNew={showAddNew}
              />
            ) : patientList?.length > 0 ? (
              <PatientList
                patientList={patientList}
                editForm={editForm}
                transferForm={transferForm}
                listCheck={listCheck}
                onSearchClicked={onSearchClicked}
              />
            ) : (
              <></>
            )}
          </Box>
          {/* <PatientList /> */}
          {/* {showForm } */}
          {/* </div> */}
          {/* </Grid> */}
        </Box>
      </Paper>
    </>
  );
};
