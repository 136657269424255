import { Paper } from "@material-ui/core";

import React, { Component } from "react";
import { withRouter } from "react-router";
import { withAllContexts } from "../../HOCs";
// import { TaskViewer } from "./TaskViewer/index";
import { TaskViewer } from "taskviewer";

class TaskViewers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      PermissionData: [],
    };
  }
  componentDidMount() {
    let token = sessionStorage.getItem("queue_Token")
    if (token) {
      var myHeaders = new Headers();
      myHeaders.append("jwttoken", sessionStorage.getItem("queue_Token"));

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        redirect: "follow",
      };

      fetch(process.env.REACT_APP_QUEUELIST, requestOptions)
        .then((response) => response.json())
        .then((result) => {
          this.setState({ PermissionData: result.result });
        })
        .catch((error) => console.log("error", error));
    }
    else {
      localStorage.removeItem("aTclk");
      localStorage.removeItem("rTclk");
      localStorage.removeItem("kclkid");
      localStorage.removeItem("RBAC");
      sessionStorage.removeItem("user");
      localStorage.removeItem("user");
      localStorage.removeItem("TriggerTiming");
      localStorage.removeItem("access_token");
      // localStorage.removeItem("RoleId")
      this.props.history.push("/");
    }
  }
  render() {
    return (
      <>
        <Paper
          style={{
            width: "100%",
            height: "100%",
            backgroundColor: "#F3F4F7",
          }}
          elevation={3}
        >
          <TaskViewer
            PermissionData={this.state.PermissionData}
            dbName={process.env.REACT_APP_DB}
            performUserAction={process.env.REACT_APP_PERFORMUSERACTION_API}
            auditdb={process.env.REACT_APP_AuditDB}
            theme={require("../../themes/default.json")}
          />
        </Paper>
      </>
    );
  }
}

export default withRouter(withAllContexts(TaskViewers));
