import React from "react";
import { withStyles } from "@material-ui/core/styles";
import TreeView from "./treeStuct";
import Grid from "@material-ui/core/Grid";
import { CommonTabList } from "../../components/common";
import { OrgView } from "./orgView";

const styles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
    height: "100%",
    backgroundColor: "white",
  },
  backgroundColor: {
    backgroundColor: "transparent",
    padding: 0,
  },
});

class Organization extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openTree: true,
      form: false,
      key: null,
      treeView: 0,
      entity: null,
      disableAddNew: false,
    };
  }
  render() {
    const hospitalsTree = () => {
      this.setState({
        openTree: !this.state.openTree,
      });
    };
    const formClick = (getkey, fromType, isEdit) => {
      this.setState({
        ...this.state,
        form: true,
        key: getkey,
        isexternal: fromType,
        disableAddNew: isEdit ? isEdit : false,
      });
    };
    const setKeyfuntion = (getkey, isEdit) => {
      let entity = getkey.split("/")[0];
      this.setState({
        key: getkey,
        entity: entity,
        form: false,
        disableAddNew: false,
      });
    };

    const completeForm = (isEdit) => {
      this.setState({
        ...this.state,
        form: false,
        disableAddNew: isEdit ? isEdit : false,
      });
    };

    const { classes, theme } = this.props;
    return (
      <div id={"organization-parent-div"} className={classes.root}>
        <Grid id={"organization-parent-grid"} container>
          {this.state.openTree ? (
            <Grid
              id={"organization-parent-sub-grid"}
              item
              xs={3}
              style={{ zIndex: 1 }}
            >
              <CommonTabList
                parent_id={"admintabpanel"}
                title={""}
                styles={{
                  backgroundColor: theme.palette.background.table,
                  borderTop: "2px solid #f6f6f6",
                }}
                list={[
                  {
                    name: "Enterprise Details",
                    component: (
                      <TreeView
                        parent_id={"treestuck"}
                        onClickTreebtn={() => hospitalsTree}
                        onClickformClick={formClick}
                        treeis={this.state.openTree}
                        isform={this.state.form}
                        getKeyfuntion={setKeyfuntion}
                        isexternal={false}
                        disableAddNew={this.state.disableAddNew}
                      />
                    ),
                  },
                ]}
              />
            </Grid>
          ) : null}

          <Grid
            id={"organization-grid"}
            item
            xs={9}
            style={{ padding: "20px" }}
          >
            <div id={"organization-div"} className={classes.paper}>
              <OrgView
                isform={this.state.form}
                handlekey={this.state.key}
                onClickformClick={formClick}
                entity={this.state.entity}
                completeForm={completeForm}
              />
            </div>
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(Organization);
