export const Routes = {
  login: "/",
  Organization: "/Organization",
  home: "/home",
  repositry: "/repositry",
  practitioner: "/practitioner",
  taskdocument: "/taskdocument",
  reportConfig: "/reportConfig",
  menu: "/menu",
  generalMaster: "/generalMaster",
  patientRegistration: "/patientRegistration",
  taskViewer: "/taskViewer",
  orderConfigurator: "/orderConfigurator",
  taskViewerConfig: "/taskViewerConfig",
  kitchenMenu: "/kitchenMenu",
  updateMealOrder: "/updateMealOrderStatus",
};
