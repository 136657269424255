import React from "react";
import {
  Card,
  Divider,
  IconButton,
  useTheme,
  // Drawer
} from "@material-ui/core";
import ArrowBackIosOutlinedIcon from "@material-ui/icons/ArrowBackIosOutlined";
import { AlertProps } from "../../utils";
import "../../components/font.css";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { actions } from "ipmo_v_dbinder";
import { CommonTable } from "../../components/common";
//import { makeStyles } from "@material-ui/core/styles";
// import { useHistory } from "react-router";
import { withAllContexts } from "../../HOCs";
import { SearchWithFilter } from "./SearchWithFilter";
import { makeStyles } from "@material-ui/core/styles";

import { Typography, Button, Grid } from "@material-ui/core/";

import { PractitionerForm } from "../../components";

//import { AlertProps } from "../../utils";

//const drawerWidth = "94%";
// const useStyles = makeStyles((theme) => ({
//   drawer: {
//     width: drawerWidth,
//     flexShrink: 0,
//   },
//   drawerPaper: {
//     width: drawerWidth,
//   },
// }));

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
  },
  title: {
    // marginLeft: theme.spacing(2),
    flex: 1,
  },
  numbersEdit: {
    marginTop: "4px",
    fontFamily: "poppinsemibold",
    padding: "10px 20px 6px",
  },
}));
function PractitionerTable(props) {
  const [loader, setLoader] = React.useState(true);
  const classes = useStyles();
  const dispatch = useDispatch();
  const theme = useTheme();
  const { parent_id } = props;

  //const classes = useStyles();

  // const listData = useSelector(
  //   (state) => state?.practitionerSlice?.practitioner_list?.data
  // );
  // const organizationSlice = useSelector((state) => state.organizationSlice);
  const loading = useSelector(
    (state) => state?.practitionerSlice?.practitioner_list?.loading
  );
  //const saveloading = useSelector((state) => state?.practitionerSlice?.practitioner_upsert?.loading);
  const [state, setState] = React.useState({
    open: false,
    edit_data: null,
  });

  const [perPage, setPerPage] = React.useState(10);
  const [page, setPage] = React.useState(0);
  const [personDetails, setPersonDetails] = React.useState([]);
  const [editData, setEditData] = React.useState({});
  const [edit, setEdit] = React.useState(false);
  const [allPersons, setAllpersons] = React.useState([]);
  const handlepageChange = async (currentPage, PerPage) => {
    setPage((currentPage - 1) * PerPage);
    setPerPage(PerPage);
    await dispatch(
      actions.PRACTITIONER_LIST_READ({
        page: (currentPage - 1) * PerPage,
        perPage: PerPage,
      })
    );
  };

  const handleDrawerOpen = (val) => {
    if (val === "new") {
      setEdit(false);
    }
    // console.log("pressed");
    setState({
      ...state,
      open: true,
    });
  };

  const showBackdrop = () => {
    props.backDrop.setBackDrop({
      ...props.backDrop,
      open: true,
      message: "processing....",
    });
  };

  // Backdrop Helpers

  const hideBackdrop = () => {
    props.backDrop.setBackDrop({
      ...props.backDrop,
      open: false,
    });
  };
  // const handleDrawerClose = () => {
  //   setState({
  //     ...state,
  //     open: false,
  //     edit_data: null
  //   });
  // };
  const getAllPerson = () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      db_name: process.env.REACT_APP_DB,
      entity: "Person",
      filter: "Person.roleid[0].roleid!='5' && Person.activestatus==true",
      return_fields:
        "MERGE(Person,{ name:(FOR nam IN TO_ARRAY(Person.name) RETURN MERGE(DOCUMENT(nam),{use:DOCUMENT(DOCUMENT(nam).use)})), address:(FOR add IN TO_ARRAY(Person.address) RETURN MERGE(DOCUMENT(add),{country:DOCUMENT(DOCUMENT(add).country),Type:DOCUMENT(DOCUMENT(add).Type) ,use:DOCUMENT(DOCUMENT(add).use) ,city:DOCUMENT(DOCUMENT(add).city) ,state:DOCUMENT(DOCUMENT(add).state) ,postalCode:DOCUMENT(DOCUMENT(add).postalCode) })), photo:DOCUMENT(Person.photo), telecom:(for tel IN TO_ARRAY(Person.telecom) RETURN MERGE(DOCUMENT(tel),{system:DOCUMENT(DOCUMENT(tel).system),use:DOCUMENT(DOCUMENT(tel).use),rank:MERGE(DOCUMENT(DOCUMENT(tel).rank),{coding:DOCUMENT(DOCUMENT(DOCUMENT(tel).rank).coding)}) })), gender:MERGE(DOCUMENT(Person.gender),{coding:(FOR cod IN TO_ARRAY(DOCUMENT(Person.gender).coding) RETURN DOCUMENT(cod))}) })",
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(process.env.REACT_APP_ARANGO_URL_READ, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        let arry = [];
        result.result.map((val) => {
          arry.push({
            ...val,
            names: val?.name[0]?.text ? val?.name[0]?.text : "-",
            types: val?.roleid?.[0]?.rolename ? val?.roleid[0].rolename : "-",
            _key: val._key,
          });
          return arry;
        });
        setAllpersons(arry);
      })
      .catch((error) => console.log("error", error));
  };
  const onSearchQuery = (search) => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      db_name: process.env.REACT_APP_DB,
      entity: "Person",
      filter: `(like(document(first(Person.name)).text,'%${search}%',true)) && Person.roleid[0].roleid!='5'&& Person.activestatus==true`,
      return_fields:
        "MERGE(Person,{ name:(FOR nam IN TO_ARRAY(Person.name) RETURN MERGE(DOCUMENT(nam),{use:DOCUMENT(DOCUMENT(nam).use)})), address:(FOR add IN TO_ARRAY(Person.address) RETURN MERGE(DOCUMENT(add),{country:DOCUMENT(DOCUMENT(add).country),Type:DOCUMENT(DOCUMENT(add).Type) ,use:DOCUMENT(DOCUMENT(add).use) ,city:DOCUMENT(DOCUMENT(add).city) ,state:DOCUMENT(DOCUMENT(add).state) ,postalCode:DOCUMENT(DOCUMENT(add).postalCode) })), photo:DOCUMENT(Person.photo), telecom:(for tel IN TO_ARRAY(Person.telecom) RETURN MERGE(DOCUMENT(tel),{system:DOCUMENT(DOCUMENT(tel).system),use:DOCUMENT(DOCUMENT(tel).use),rank:MERGE(DOCUMENT(DOCUMENT(tel).rank),{coding:DOCUMENT(DOCUMENT(DOCUMENT(tel).rank).coding)}) })), gender:MERGE(DOCUMENT(Person.gender),{coding:(FOR cod IN TO_ARRAY(DOCUMENT(Person.gender).coding) RETURN DOCUMENT(cod))}) })",
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(process.env.REACT_APP_ARANGO_URL_READ, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        let arry = [];
        result.result.map((val) => {
          arry.push({
            ...val,
            names: val?.name[0]?.text ? val?.name[0]?.text : "-",
            types: val?.roleid?.[0]?.rolename ? val?.roleid[0].rolename : "-",
            _key: val._key,
          });
          return arry;
        });
        setAllpersons(arry);
      })
      .catch((error) => console.log("error", error));
  };
  React.useLayoutEffect(() => {
    getAllPerson();
    const getdata = async () => {
      await dispatch(actions.ORGANIZATION_ENTITY_TYPE());
      // await dispatch(actions.ORGANIZATION_GET_LEVEL_CARE());
      // await dispatch(
      //   actions.PRACTITIONER_LIST_READ({ page: page, perPage: perPage })
      // );
    };
    getdata();
  }, [dispatch]);

  // const savePractitionerRole = async (values) => {
  //   let data = {
  //     code: values.type_code,
  //     description: values.type_description,
  //     type: props.type,
  //     status: values.status,
  //   }
  //   if (state?.edit_data) {
  //     data["_key"] = state?.edit_data._key
  //     data["id"] = state?.edit_data.id
  //   }
  //   await dispatch(actions.PRACTITIONER_ROLE_UPSERT(data));
  //   await dispatch(actions.PRACTITIONER_LIST_READ({ type: props.type }));
  //   await handleDrawerClose()
  // }

  const handleEdit = (e, values, index) => {
    // console.log(values, index, allPersons);
    setState({ open: true });
    setEdit(true);
    let onePerson = allPersons?.filter((e) => e._id === values._id);
    // console.log("onePerson", onePerson, allPersons, values);
    setEditData(onePerson);
    // let data = {
    //   code: values.code_type,
    //   description: values.description,
    //   type: props.type,
    //   status: values.status,
    //   _key: values._key,
    //   id: values.id
    // }
    // setState({
    //   ...state,
    //   edit_data: data,
    //   open: true
    // })
  };
  const handleSave = async () => {
    // debugger;
    if (
      personDetails?.birthDay.length === 10 ||
      personDetails?.birthDay.length === 0
    ) {
      if (Valid()) {
        try {
          showBackdrop();
          // const response = [];
          const response = await dispatch(actions.PERSON_UPSERT(personDetails));
          if (response?.payload?.data?.Code === 201) {
            setLoader(true);
            hideBackdrop();
            props.alert.setSnack({
              ...props.alert,
              horizontal: AlertProps.horizontal.right,
              msg: "Role Mapped successfully",
              open: true,
              severity: AlertProps.severity.success,
              vertical: AlertProps.vertical.top,
            });
            setEdit(false);
            setEditData({});
            // dispatch(
            //   actions.PRACTITIONER_LIST_READ({ page: page, perPage: perPage })
            // );
            getAllPerson();
            hideBackdrop();
            handleClose();
            setLoader(true);
          } else if (response?.payload?.data?.Code === 1111) {
            hideBackdrop();
            return props.alert.setSnack({
              ...props.alert,
              horizontal: AlertProps.horizontal.right,
              msg: `${response?.payload?.data?.error}`,
              open: true,
              severity: AlertProps.severity.error,
              vertical: AlertProps.vertical.top,
            });
          } else {
            hideBackdrop();
            return props.alert.setSnack({
              ...props.alert,
              horizontal: AlertProps.horizontal.right,
              msg: "Role Mapping failed!",
              open: true,
              severity: AlertProps.severity.error,
              vertical: AlertProps.vertical.top,
            });
          }
        } catch (error) {
          hideBackdrop();
          return props.alert.setSnack({
            ...props.alert,
            horizontal: AlertProps.horizontal.right,
            msg: error,
            open: true,
            severity: AlertProps.severity.error,
            vertical: AlertProps.vertical.top,
          });
        }
      } else {
        props.alert.setSnack({
          ...props.alert,
          horizontal: AlertProps.horizontal.right,
          msg: "Required Fields Are Empty",
          open: true,
          severity: AlertProps.severity.error,
          vertical: AlertProps.vertical.top,
        });
      }
    } else {
      props.alert.setSnack({
        ...props.alert,
        horizontal: AlertProps.horizontal.right,
        msg: "Please Check The Date Of Birth",
        open: true,
        severity: AlertProps.severity.error,
        vertical: AlertProps.vertical.top,
      });
    }
  };

  const sendData = (keyValue, value, newval) => {
    setPersonDetails(newval);
  };
  // console.log("personDetails", personDetails);
  const handleClose = () => {
    setState({ ...state, open: false });
    setPersonDetails([]);
    setEdit(false);
    setEditData({});
  };
  const Valid = () => {
    var ValidData = false;
    const FormData = {
      firstName: personDetails?.name?.[0]?.text,
      lastName: personDetails?.name?.[0]?.given,
      gender: personDetails?.gender,
      email: personDetails?.telecom?.[0]?.value,
      roleid: personDetails?.roleid?.[0]?.roleid,
      orgType: personDetails?.orgType,
      OrgID: personDetails?.OrgID?.[0]?.id,
    };
    if (
      Object.values(FormData).every(
        (val) => val !== undefined && val.length !== 0
      )
    ) {
      ValidData = true;
      return ValidData;
    } else {
      return ValidData;
    }
  };
  const handleUpdate = () => {
    if (
      personDetails?.birthDay.length === 10 ||
      personDetails?.birthDay.length === 0
    ) {
      if (Valid()) {
        showBackdrop();
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("tenantid", process.env.REACT_APP_IDM_TENANT_ID);
        var raw = JSON.stringify([
          {
            db_name: process.env.REACT_APP_DB,
            entity: process.env.REACT_APP_PERSON_ENTITY,
            filter: {
              _key: edit ? editData[0]._key : "",
            },
            is_metadata: true,
            metadataId: process.env.REACT_APP_METADATAID,
            metadata_dbname: process.env.REACT_APP_REPORT_DBNAME,
            doc: {
              ...personDetails,
            },
          },
        ]);
        // console.log(JSON.parse(raw));
        var requestOptions = {
          method: "POST",
          headers: myHeaders,
          body: raw,
          redirect: "follow",
        };
        fetch(process.env.REACT_APP_UPSERT_IDM_PESRON, requestOptions)
          .then((response) => response.json())
          .then((result) => {
            setLoader(true);
            dispatch(
              actions.PRACTITIONER_LIST_READ({ page: page, perPage: perPage })
            );
            getAllPerson();
            props.alert.setSnack({
              ...props.alert,
              horizontal: AlertProps.horizontal.right,
              msg: "Person Updated successfully",
              open: true,
              severity: AlertProps.severity.success,
              vertical: AlertProps.vertical.top,
            });

            hideBackdrop();
            handleClose();
          })
          .catch((error) => console.log("error", error));
      } else if (personDetails.length === 0) {
        props.alert.setSnack({
          ...props.alert,
          horizontal: AlertProps.horizontal.right,
          msg: "Cant Update Without Any Changes",
          open: true,
          severity: AlertProps.severity.error,
          vertical: AlertProps.vertical.top,
        });
      } else {
        props.alert.setSnack({
          ...props.alert,
          horizontal: AlertProps.horizontal.right,
          msg: "Required Fields Are Empty",
          open: true,
          severity: AlertProps.severity.error,
          vertical: AlertProps.vertical.top,
        });
      }
    } else {
      props.alert.setSnack({
        ...props.alert,
        horizontal: AlertProps.horizontal.right,
        msg: "Please Check The Date Of Birth",
        open: true,
        severity: AlertProps.severity.error,
        vertical: AlertProps.vertical.top,
      });
    }
  };
  const statusChange = async (e, values, index) => {
    let data = {
      code: values.code_type,
      description: values.description,
      type: props.type,
      status: e.target.checked,
      _key: values._key,
      id: values.id,
    };
    setState({
      ...state,
      edit_data: data,
    });
    props.backDrop.setBackDrop({
      ...props.backDrop,
      open: true,
      message: "Status Updating",
    });

    await dispatch(actions.PRACTITIONER_STATUS_CHANGE(data));
    await dispatch(
      actions.PRACTITIONER_LIST_READ({
        page: page,
        perPage: perPage,
        type: props.type,
      })
    );
    setState({
      ...state,
      edit_data: null,
    });
    props.backDrop.setBackDrop({
      ...props.backDrop,
      open: false,
      message: "",
    });
    props.alert.setSnack("update");
  };

  // console.log(personDetails);
  const onSearchChange = (value = "") => {
    if (value?.length > 2) {
      onSearchQuery(value.trim());
    } else {
      getAllPerson();
    }
  };
  return (
    <React.Fragment>
      {!state.open && (
        // <Card id={`${parent_id}-parent-card`} style={{ borderRadius: "12px" }}>
        <div
          id={`${parent_id}-parent-div`}
          style={{ width: "100%", padding: "5px" }}
        >
          <Card
            id={`${parent_id}-parent-card`}
            style={{ borderRadius: "12px" }}
          >
            <div
              id={`${parent_id}-parent-div`}
              style={{
                borderRadius: "6px",
              }}
            >
              <div
                id={`${parent_id}-header-button-div`}
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  background: theme.palette.background.table,
                }}
              >
                <div
                  id={`${parent_id}-header-div`}
                  style={{ marginLeft: "18px" }}
                >
                  <p
                    id={`${parent_id}-${props?.header?.replaceAll?.(
                      " ",
                      "-"
                    )}-p`}
                    style={{
                      fontFamily: "poppinsemibold",
                      fontSize: "16px",
                      marginBottom: "0px",
                    }}
                  >
                    <Typography className={classes.numbersEdit}>
                      Person
                    </Typography>
                  </p>
                  <div></div>
                </div>
                <div
                  id={`${parent_id}-Add-New-button-div`}
                  style={{
                    marginTop: "16px",
                    float: "right",
                    marginRight: "20px",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <SearchWithFilter
                    id="symptomyandspeciality"
                    placeholder="Search code and Description!"
                    //  loading={data?.length > 0 ? loading : false}
                    onSearchChange={onSearchChange}
                    hideSearchBar={false}
                    hideFilter={true}
                    //  onFilterClicked={onFilterClicked}
                    //  isFilterApplied={props?.isFilterApplied}
                  />
                  &nbsp;
                  <button
                    id={`${parent_id}-Add-New-button`}
                    style={{
                      backgroundColor: "#0071F2",
                      color: "white",
                      border: "none",
                      paddingTop: "5px",
                      paddingBottom: "5px",
                      borderRadius: "5px",
                      fontFamily: "poppin",
                      fontSize: "12px",
                      cursor: "pointer",
                    }}
                    onClick={() => handleDrawerOpen("new")}
                  >
                    +Add New
                  </button>
                </div>
              </div>

              {/* table */}
              <div
                id={`${parent_id}-CommonTable-div`}
                style={{
                  padding: "20px",
                  height: "70vh",
                  overflow: "auto",
                  background: theme.palette.background.table,
                }}
              >
                {/* {
                (loading && state.edit_data === null) ?
                  <div
                    id={`${parent_id}-CircularProgress-div`}
                    style={{ display: 'flex', justifyContent: "center", alignItems: "center", minHeight: "66vh" }}>
                    <CircularProgress
                      id={`${parent_id}-CircularProgress`}
                    />
                  </div>
                  : */}
                <CommonTable
                  parent_id={"practitioner"}
                  Header={[
                    "S.No",
                    "Name",
                    // "Primary Specialty",
                    "Type",
                    // "Status",
                    "Action",
                  ]}
                  dataList={allPersons}
                  tableData={[
                    { type: ["INCRIMENT"], name: "" },
                    { type: ["TEXT"], name: "names" },
                    // { type: ["TEXT"], name: "primaryspeciality" },
                    { type: ["TEXT"], name: "types" },
                    // { type: ["CHECKBOX"], name: "status" },
                    { type: ["EDIT"], name: "" },
                  ]}
                  handleCheckBox={statusChange}
                  handleEdit={handleEdit}
                  handlepageChange={(currentPage, PerPage) =>
                    handlepageChange(currentPage, PerPage)
                  }
                  TableCount={allPersons?.length}
                  incrementCount={page}
                  showPagination={false}
                  rowsPerPageOptions={[
                    { label: "10 Rows", value: 10 },
                    { label: "50 Rows", value: 50 },
                    { label: "100 Rows", value: 100 },
                  ]}
                  loading={loading}
                />
                {/* } */}
              </div>
            </div>
          </Card>
        </div>
        //</React.Fragment> </Card>
      )}
      {state.open && (
        <React.Fragment>
          <Grid
            container
            style={{
              // backgroundColor: "#ECF0F7",
              margin: "5px",
              padding: "0 10px",
              height: "90%",
              border: "1px solid #D0D0D0",
              boxShadow: "3px 4px 11px -6px rgba(0,0,0,0.7)",
              borderRadius: "8px",
            }}
          >
            <Grid item sm={12} xs={12} md={12} xl={12} lg={12}>
              <div
                style={{
                  display: "flex",
                  cursor: "pointer",
                  alignItems: "center",
                }}
              >
                <div>
                  <IconButton size="small" onClick={() => handleClose()}>
                    <ArrowBackIosOutlinedIcon />
                  </IconButton>
                </div>
                <Typography className={classes.numbersEdit}>
                  Person Registration
                </Typography>
              </div>
              <Divider variant="fullWidth" />
            </Grid>

            <PractitionerForm
              sendData={sendData}
              loading={loader}
              edit={edit}
              editData={editData}
            />
            <Grid
              item
              sm={12}
              xs={12}
              lg={12}
              xl={12}
              md={12}
              style={{
                display: "flex",
                justifyContent: "flex-end",
                margin: "10px 0",
              }}
            >
              <Button
                autoFocus
                size="small"
                variant="contained"
                style={{
                  margin: "0 5px ",
                  backgroundColor: "#4CAF50",
                  color: "white",
                }}
                // disabled={edit}
                onClick={() => (edit ? handleUpdate() : handleSave())}
              >
                {edit ? "Update" : "save"}
              </Button>
              <Button
                style={{ margin: "0 0 0 5px" }}
                autoFocus
                variant="contained"
                size="small"
                color="inherit"
                // disabled={edit}
                onClick={handleClose}
              >
                Cancel
              </Button>
            </Grid>
          </Grid>
        </React.Fragment>
      )}
      {/* <Dialog
        fullScreen
        open={state.open}
        onClose={handleClose}
        TransitionComponent={Transition}
        PaperProps={{
          style: {
            backgroundColor: "#F3F4F7",
            boxShadow: "none",
          },
        }}
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              Person Registration
            </Typography>
            <Button
              autoFocus
              color="inherit"
              // disabled={edit}
              onClick={() => (edit ? handleUpdate() : handleSave())}
            >
              {edit ? "Update" : "save"}
            </Button>
          </Toolbar>
        </AppBar>


      </Dialog> */}
    </React.Fragment>
  );
}
export default withAllContexts(PractitionerTable);
