import { Paper } from "@material-ui/core";
import { actions } from "ipmo_v_dbinder";
import React from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { VisitRegistration } from "./visitRegistration";

const PatientRegistration = () => {
  const dispatch = useDispatch();

  const PatientList = useSelector(
    (state) => state?.patientRegistration?.searchPatient
  );

  React.useEffect(() => {
    getPesron();

    // getUserRole(id);
  }, []);

  const getPesron = async () => {
    let id = localStorage.getItem("kclkid");
    await dispatch(actions.PERSON_LOGGEDIN({ id: id }));
  };

  return (
    <>
      <Paper
        style={{
          width: "100%",
          height: "100%",
          // backgroundColor: "#F3F4F7",
          // padding: "8px",
          borderRadius: "0px",
        }}
        // elevation={3}
      >
        <VisitRegistration data={PatientList} />
      </Paper>
    </>
  );
};

export default PatientRegistration;
