import {
  Box,
  CircularProgress,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { actions } from "ipmo_v_dbinder";
import React from "react";
import { useDispatch } from "react-redux";
import { SearchTextField } from "../../components";
import { ListOfCards } from "./listOfCards";
const useStyles = makeStyles((theme) => ({
  body: {
    height: "calc(100% - 55px)",
    padding: "8px",
    margin: "16px 0",
  },
  boxCenter: {
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  captionText: {
    fontWeight: "600",
    color: theme.palette.text.disabled,
  },
}));

export const KitchenOrderingForPatient = (props) => {
  const { updateSelectedPatient } = props;
  const classes = useStyles();
  const [search, setSearch] = React.useState("");
  const [patientList, setPatientList] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const dispatch = useDispatch();
  const [eptyScrnMesg, setEptyScrnMesg] = React.useState(
    "Please search by bed number"
  );

  const onHandleChange = (e) => {
    if (e.target.value.length !== 0) {
      setSearch(e.target.value);
    } else {
      setSearch("");
      setPatientList([]);
    }
  };

  const onEnterClicked = (e) => {
    if (e.key === "Enter") {
      onSearchClicked();
    }
  };

  const onSearchClicked = async () => {
    setLoading(true);
    let searchValue = search;

    if (search.trim().length > 0) {
      let res = await dispatch(
        actions.SEARCH_BY_BED_NUMBER({ bedNumber: searchValue })
      );

      if (res?.payload?.data.length > 0) {
        setPatientList(res?.payload?.data);
        setLoading(false);
      } else {
        setLoading(false);
        setPatientList([]);

        setEptyScrnMesg("No data available");
      }
    } else {
      setLoading(false);
      setPatientList([]);
      setEptyScrnMesg("No data available");
    }
  };

  return (
    <>
      <SearchTextField
        value={search}
        onHandleChange={(e) => onHandleChange(e)}
        onKeyPress={(e) => onEnterClicked(e)}
        onSearchClicked={() => onSearchClicked()}
        placeholder="Search by bed number"
      />
      <Box className={classes.body}>
        {loading && patientList?.length === 0 && (
          <div className={classes.boxCenter}>
            <CircularProgress />
          </div>
        )}
        {!loading && patientList?.length === 0 && (
          <div className={classes.boxCenter}>
            <Typography variant="body2" className={classes?.captionText}>
              {`" ${eptyScrnMesg} "`}
            </Typography>
          </div>
        )}
        {!loading && patientList?.length > 0 && (
          <ListOfCards
            patientList={patientList}
            updateSelectedPatient={updateSelectedPatient}
          />
        )}
      </Box>
    </>
  );
};
