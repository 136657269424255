import { Button, Grid, makeStyles } from "@material-ui/core";
import React from "react";
import { FormOrg } from "./formOrg";
import orgEntityName from "../../assets/org_entity.svg";
import orgCode from "../../assets/org_code.svg";
import orgParent from "../../assets/org_parent.svg";
import orgLevel from "../../assets/org_level.svg";
import org1Level from "../../assets/orgs_level.svg";
import BusinessIcon from "@material-ui/icons/Business";
import { useDispatch } from "react-redux";
import { actions } from "ipmo_v_dbinder";
import { useSelector } from "react-redux";
import { IoMdBed } from "react-icons/io";
import { MdMeetingRoom } from "react-icons/md";
import Config from "../../config";
import { AxiosCall } from "../../api";

const useStyles = makeStyles((theme) => ({
  titleDev: {
    height: "7vh",
    background: theme.palette.background.table,
    display: "flex",
    alignItems: "center",
    paddingLeft: "4%",
    position: "relative",
    borderLeft: "2px solid #f6f6f6",
  },
  left_arrow: {
    padding: 6,
    backgroundColor: "white",
    position: "absolute",
    left: "-1%",
    bottom: "-26%",
    "& svg": {
      fontSize: 16,
      paddingLeft: 4,
    },
    boxShadow: "0 0px 1px 0 rgb(0 0 0 / 20%)",
    border: "1px solid #dddddd;",
    zIndex: 999,
  },
  rightSide: {
    right: 16,
    textAlign: "right",
    position: "relative",
  },
  leftSide: {
    textAlign: "left",
    left: 24,
    position: "relative",
  },
  Div: {
    paddingTop: "1%",
    display: "flex",
    alignItems: "center",
  },
  hosp_name: {
    fontSize: 16,
    color: theme.palette.text.primary,
    fontWeight: "bold",
  },
  hosp_pic: {
    borderRadius: 20,
  },
  detailTitle: {
    color: theme.palette.text.primary,
    fontWeight: "bold",
    fontSize: 14,
    textAlign: "left",
  },
  detailBox: {
    display: "flex",
    alignItems: "center",
    paddingBottom: "1%",
  },
  boxFirstDiv: {
    marginRight: "2%",
    marginBottom: "2%",
  },
  detailData: {
    marginLeft: "2.6vh",
    textAlign: "left",
  },
  iconStyle: {
    fontSize: "18px",
    color: "#0071F2",
    marginRight: 4,
  },
  addressbox: {
    padding: 12,
  },
  root: {
    "& .App1-MuiTabs-root": {
      "& .App1-MuiTabs-scroller": {
        "& .App1-MuiTabs-flexContainer": {
          alignItems: "center",
          justifyContent: "flex-start !important",
          margin: "0px 17px",
          "& .App1-MuiButtonBase-root": {
            marginRight: "2% !important",
          },
        },
      },
    },
  },
  detailDiv: {
    padding: 20,
    paddingBottom: "0px",
    marginTop: "2%",
  },
  detailDiv1: {
    padding: "16px 20px 10px 20px",
  },
  wrapper: {
    padding: "0px 20px",
  },
  buttonStyle: {
    background: theme.palette.button.background,
    color: theme.palette.button.text,
    "&:hover": {
      background: theme.palette.button.hover,
    },
  },
}));
export const OrgView = (props) => {
  const { isform, handlekey, entity, onClickformClick, completeForm } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const parent_id = "0001";
  const [bedStatus, setBedStatus] = React.useState();
  const [edit, setEdit] = React.useState({
    isEdit: false,
    editData: null,
  });
  const entityDetails = useSelector(
    (state) => state?.organizationSlice?.getOrgDetails
  );

  React.useEffect(() => {
    if (handlekey) {
      getOrgDetails(handlekey);
    }
    if (!isform) {
      setEdit({
        ...edit,
        isEdit: false,
        editData: null,
      });
    }
    // if (entityDetails?.data[0]?.entityType === "Bed") {
    //   getBedStatus(handlekey);
    // }

    //eslint-disable-next-line
  }, [handlekey, isform]);

  const getOrgDetails = async () => {
    let res = await dispatch(
      actions.GET_ORG_DETAILS({
        key: handlekey,
        type: entity,
      })
    );
    let data = res?.payload?.data;
    if (data[0]?.entityType === "Bed") {
      await getBedStatus(handlekey);
    }
  };

  const handleEdit = (data) => {
    setEdit({
      ...edit,
      isEdit: true,
      editData: data,
    });
    onClickformClick(handlekey, "", true);
  };

  const UpdateDone = async () => {
    setEdit({
      ...edit,
      isEdit: false,
      editData: null,
    });
    completeForm(false);
    await dispatch(actions.ORGANIZATION_TREE_READ({ isexternal: false }));
  };

  const backButtonClicked = () => {
    setEdit({
      ...edit,
      isEdit: false,
      editData: null,
    });
    completeForm(false);
  };

  const getBedStatus = async (id) => {
    //debugger;
    let params = {
      db_name: Config.dbname,
      entity: "PatientCheckIn",
      filter: `PatientCheckIn.BedCode=='${id}' and PatientCheckIn.activestatus==true`,
      return_fields:
        "MERGE(PatientCheckIn,{Status:document(PatientCheckIn.Status)})",
    };

    let res = await AxiosCall(
      "post",
      `${Config.api_url}/api/read_documents`,
      params
    );

    if (res?.result?.length > 0) {
      setBedStatus("Occupied");
    } else {
      setBedStatus("Not occupied");
    }
  };

  // const onDeleteClicked = (id) => {
  //   dialog.setDialog({
  //     ...dialog,
  //     open: true,
  //     title: "Are you sure you want to delete this record?",
  //     body: "",
  //     positiveBtn: "Confirm",
  //     negativeBtn: "Cancel",
  //     onOk: () => confrimDelete(id),
  //   });
  // };

  // const confrimDelete = async (id) => {
  //   dialog.setDialog({
  //     ...dialog,
  //     open: false,
  //   });
  // };

  return (
    <>
      {isform ? (
        !edit.isEdit ? (
          <FormOrg
            UpdateDone={UpdateDone}
            backButtonClicked={backButtonClicked}
          />
        ) : (
          <FormOrg
            isEdit={edit?.isEdit}
            editData={edit?.editData}
            UpdateDone={UpdateDone}
            backButtonClicked={backButtonClicked}
          />
        )
      ) : (
        <>
          {entityDetails?.data?.length > 0 ? (
            <div id={`${parent_id}-parent-sub-div`}>
              <Grid
                id={`${parent_id}-entity-grid`}
                container
                className={classes.Div}
              >
                <Grid
                  id={`${parent_id}-entity-sub-grid`}
                  item
                  xs={6}
                  className={classes.leftSide}
                >
                  <p
                    id={`${parent_id}-entity-text-p`}
                    className={classes.hosp_name}
                  >
                    {entityDetails?.data[0]?.entityName ?? "-"}
                  </p>
                </Grid>
                <Grid
                  id={`${parent_id}-buttons-grid`}
                  item
                  xs={6}
                  className={classes.rightSide}
                >
                  {/* {entityDetails?.data[0]?.entityType === "Bed" && (
                    <IconButton
                      onClick={() => onDeleteClicked(entityDetails?.data?._id)}
                    >
                      <DeleteOutlinedIcon color="error" />
                    </IconButton>
                  )} */}

                  <Button
                    id={`${parent_id}-Edit-button`}
                    variant="contained"
                    color="primary"
                    size="small"
                    onClick={() => handleEdit(entityDetails?.data[0])}
                    className={classes.buttonStyle}
                  >
                    Edit
                  </Button>
                </Grid>
              </Grid>
              <Grid
                id={`${parent_id}-parent-grid`}
                container
                className={classes.Div}
                style={{ paddingTop: 0 }}
              >
                <Grid
                  id={`${parent_id}-parent-sub-grid`}
                  item
                  xs={12}
                  style={{
                    borderRadius: 14,
                    padding: 22,
                    margin: 12,
                  }}
                >
                  <Grid
                    id={`${parent_id}-parent-sublevel-grid`}
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                  >
                    <Grid id={`${parent_id}-title-grid`} item xs={10}>
                      <Grid id={`${parent_id}-title-sub-grid`} xs={12}>
                        <Grid
                          id={`${parent_id}-ENTITY-DETAILS-grid`}
                          style={{ paddingLeft: "15px" }}
                          container
                        >
                          <Grid
                            id={`${parent_id}-ENTITY-DETAILS-title-grid`}
                            item
                            xs={2}
                            className={classes.detailTitle}
                          >
                            <span>ENTITY DETAILS</span>{" "}
                          </Grid>
                          <Grid
                            id={`${parent_id}-ENTITY-DETAILS-divider-grid`}
                            item
                            xs={10}
                          >
                            <hr
                              id={`${parent_id}-ENTITY-DETAILS-divider`}
                              style={{ border: "1px solid #f1f1f1" }}
                            />
                          </Grid>
                        </Grid>
                        <Grid
                          id={`${parent_id}-titles-grid`}
                          container
                          direction="row"
                          justifyContent="space-between"
                          alignItems="center"
                          className={classes.detailDiv}
                        >
                          <Grid item md={4} xs={12}>
                            <div
                              id={`${parent_id}-titles-div`}
                              className={classes.boxFirstDiv}
                            >
                              <div
                                id={`${parent_id}-Entity-Name-title-div`}
                                className={classes.detailBox}
                              >
                                <img
                                  id={`${parent_id}-Entity-Name-title-img`}
                                  src={orgEntityName}
                                  className={classes.iconStyle}
                                  alt="pic"
                                />
                                Entity Name
                              </div>
                              <div
                                id={`${parent_id}-Entity-Name-treedata-div`}
                                className={classes.detailData}
                              >
                                {entityDetails?.data[0]?.entityName ?? "-"}
                              </div>
                            </div>
                          </Grid>
                          <Grid item md={4} xs={12}>
                            <div
                              id={`${parent_id}-Entity-Code-title-div`}
                              className={classes.boxFirstDiv}
                            >
                              <div
                                id={`${parent_id}-Entity-Code-title-sub-div`}
                                className={classes.detailBox}
                              >
                                <img
                                  id={`${parent_id}-Entity-Code-orgCode-img`}
                                  src={orgCode}
                                  className={classes.iconStyle}
                                  alt="pic"
                                />
                                Entity Type
                              </div>
                              <div
                                id={`${parent_id}-Entity-Code-treedata-div`}
                                className={classes.detailData}
                              >
                                {entityDetails?.data[0]?.entityType ?? "-"}
                              </div>
                            </div>
                          </Grid>
                          {entityDetails?.data[0]?.entityType !==
                            "Organization" && (
                            <Grid item md={4} xs={12}>
                              <div
                                id={`${parent_id}-Parent-Entity-div`}
                                className={classes.boxFirstDiv}
                              >
                                <div
                                  id={`${parent_id}-Parent-Entity-title-div`}
                                  className={classes.detailBox}
                                >
                                  <img
                                    id={`${parent_id}-Parent-Entity-img`}
                                    src={orgParent}
                                    className={classes.iconStyle}
                                    alt="pic"
                                  />
                                  Parent Entity
                                </div>
                                <div
                                  id={`${parent_id}-Parent-Entity-treedata-div`}
                                  className={classes.detailData}
                                >
                                  {entityDetails?.data[0]?.parentEntity?.name ??
                                    "-"}
                                </div>
                              </div>
                            </Grid>
                          )}
                        </Grid>

                        <Grid
                          id={`${parent_id}-titles-grid`}
                          container
                          direction="row"
                          justifyContent="space-between"
                          alignItems="center"
                          className={classes.detailDiv}
                        >
                          <Grid item md={4} xs={12}>
                            <div
                              id={`${parent_id}-Level-of-care-title-div`}
                              className={classes.boxFirstDiv}
                            >
                              <div
                                id={`${parent_id}-Level-of-care-title-sub-div`}
                                className={classes.detailBox}
                              >
                                <img
                                  id={`${parent_id}-Level-of-care-orgLevel-img`}
                                  src={orgLevel}
                                  className={classes.iconStyle}
                                  alt="pic"
                                />
                                Entity Description
                              </div>
                              <div
                                id={`${parent_id}-Level-of-care-treedata-div`}
                                className={classes.detailData}
                              >
                                {entityDetails?.data[0]?.entityDescription ??
                                  "-"}
                              </div>
                            </div>
                          </Grid>
                          {entityDetails?.data[0]?.entityType ===
                            "Facility" && (
                            <Grid item md={4} xs={12}>
                              <div
                                id={`${parent_id}-Level-title-div`}
                                className={classes.boxFirstDiv}
                              >
                                <div
                                  id={`${parent_id}-Level-orgLevel-div`}
                                  className={classes.detailBox}
                                >
                                  <img
                                    id={`${parent_id}-Level-orgLevel-img`}
                                    src={org1Level}
                                    className={classes.iconStyle}
                                    alt="pic"
                                  />
                                  Facility Address
                                </div>
                                <div
                                  id={`${parent_id}-Level-treedata-div`}
                                  className={classes.detailData}
                                >
                                  {entityDetails?.data[0]?.facilityAddress ??
                                    "-"}
                                </div>
                              </div>
                            </Grid>
                          )}

                          {entityDetails?.data[0]?.entityType === "Bed" && (
                            <>
                              <Grid item md={4} xs={12}>
                                <div
                                  id={`${parent_id}-Level-title-div`}
                                  className={classes.boxFirstDiv}
                                >
                                  <div
                                    id={`${parent_id}-Level-orgLevel-div`}
                                    className={classes.detailBox}
                                  >
                                    <IoMdBed className={classes.iconStyle} />
                                    Bed Status
                                  </div>
                                  <div
                                    id={`${parent_id}-Level-treedata-div`}
                                    className={classes.detailData}
                                  >
                                    {bedStatus ?? "-"}
                                  </div>
                                </div>
                              </Grid>
                              <Grid item md={4} xs={12}>
                                <div
                                  id={`${parent_id}-Level-title-div`}
                                  className={classes.boxFirstDiv}
                                >
                                  <div
                                    id={`${parent_id}-Level-orgLevel-div`}
                                    className={classes.detailBox}
                                  >
                                    <IoMdBed className={classes.iconStyle} />
                                    Bed Type
                                  </div>
                                  <div
                                    id={`${parent_id}-Level-treedata-div`}
                                    className={classes.detailData}
                                  >
                                    {entityDetails?.data[0]?.bedType ?? "-"}
                                  </div>
                                </div>
                              </Grid>
                            </>
                          )}
                          {entityDetails?.data[0]?.entityType === "Room" && (
                            <Grid item md={4} xs={12}>
                              <div
                                id={`${parent_id}-Level-title-div`}
                                className={classes.boxFirstDiv}
                              >
                                <div
                                  id={`${parent_id}-Level-orgLevel-div`}
                                  className={classes.detailBox}
                                >
                                  <MdMeetingRoom
                                    className={classes.iconStyle}
                                  />
                                  Room Type
                                </div>
                                <div
                                  id={`${parent_id}-Level-treedata-div`}
                                  className={classes.detailData}
                                >
                                  {entityDetails?.data[0]?.roomType ?? "-"}
                                </div>
                              </div>
                            </Grid>
                          )}
                          {entityDetails?.data[0]?.entityType === "Room" && (
                            <Grid item md={4} xs={12}>
                              <div
                                id={`${parent_id}-Level-title-div`}
                                className={classes.boxFirstDiv}
                              >
                                <div
                                  id={`${parent_id}-Level-orgLevel-div`}
                                  className={classes.detailBox}
                                >
                                  <MdMeetingRoom
                                    className={classes.iconStyle}
                                  />
                                  Room Availablity
                                </div>
                                <div
                                  id={`${parent_id}-Level-treedata-div`}
                                  className={classes.detailData}
                                >
                                  {entityDetails?.data[0]?.roomAvailablity ??
                                    "-"}
                                </div>
                              </div>
                            </Grid>
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </div>
          ) : (
            <div
              id={`${parent_id}-BusinessIcon-div`}
              style={{ display: "grid", height: "80vh" }}
            >
              <div
                id={`${parent_id}-BusinessIcon-sub-div`}
                style={{
                  margin: "auto",
                }}
              >
                <BusinessIcon
                  id={`${parent_id}-BusinessIcon`}
                  style={{ fontSize: 120, color: "#e0e0e0" }}
                />
                <p
                  id={`${parent_id}-Organization-p`}
                  style={{ color: "#d0d0d0", fontSize: 18 }}
                >
                  Select Organization
                </p>
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
};
