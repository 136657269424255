import { Box, Button, Grid, TextField, Typography } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import React from "react";
import { AlertProps, ToastMessage } from "../../utils";
import { actions } from "ipmo_v_dbinder";
import { useDispatch } from "react-redux";
import { FlexRow } from "../../components";
import { AxiosCall } from "../../api";
import Config from "../../config";
import { AlertContext } from "../../contexts";
export const FormOrg = (props) => {
  const {
    isEdit = false,
    editData = null,
    UpdateDone,
    backButtonClicked,
  } = props;
  const dispatch = useDispatch();
  let newdata = {
    entityType: "",
    entityName: "",
    parentEntity: "",
    entityAddress: "",
    entityDescription: "",
    roomType: "",
    bedType: "",
    facilityAddress: "",
  };

  let editvalue = {
    ...editData,
    parentEntity: editData?.parentEntity?.id,
    ...(editData?.entityType === "Bed" && {
      LoginCount: editData?.LoginCount ?? 0,
    }),
  };
  const data = isEdit ? editvalue : newdata;
  const [state, setState] = React.useState(data);
  const alertContext = React.useContext(AlertContext);
  const [parentEntity, setParent] = React.useState([]);

  const entityList = [
    { label: "Organization" },
    { label: "Facility" },
    { label: "Ward" },
    { label: "Room" },
    { label: "Bed" },
  ];

  React.useEffect(() => {
    if (isEdit) {
      getParent(editData?.entityType);
    }
    //eslint-disable-next-line
  }, [isEdit, editData]);

  const getParent = async (type) => {
    let res = await dispatch(actions.GET_PARENT_ENTITY(type ?? "Organization"));

    setParent(res?.payload?.data ?? []);
  };

  const handleChange = (key, value) => {
    // debugger;
    if (value && key === "entityType" && value !== "Organization") {
      getParent(value);
      setState({ ...state, [key]: value });
    } else if (value === "Organization") {
      setState({ ...state, [key]: value });
    } else {
      setState({ ...state, [key]: value || "" });
    }
  };

  const getParentValue = (value) => {
    // debugger;
    let p = parentEntity?.find((l) => l?.value === value) ?? {};

    return p;
  };

  const ValidateValue = (val) => {
    return val?.trim()?.length === 0 ? false : true;
  };

  const valid = () => {
    //debugger;
    let hasEnityType = ValidateValue(state?.entityType);
    let hasEnityName = ValidateValue(state?.entityName);
    let hasParentType =
      state?.entityType !== "Organization"
        ? ValidateValue(state?.parentEntity)
        : true;

    if (hasEnityType && hasEnityName && hasParentType) {
      return true;
    } else {
      ToastMessage("Required Fields are empty", "error");
      return false;
    }
  };

  const onSaveCliked = async () => {
    let _v = valid();
    if (_v) {
      if (state?.entityType === "Bed") {
        let data = await checkBedNumberAlreadyExist(state?.entityName);
        if (!isEdit && data?.length > 0) {
          alertContext.setSnack({
            ...alertContext,
            open: true,
            severity: AlertProps.severity.error,
            msg: "BedNumber Already Exist!",
            vertical: AlertProps.vertical.top,
            horizontal: AlertProps.horizontal.center,
          });
        } else {
          await dispatch(
            actions.UPSERT_ORAGANIZATION({
              state: state,
              key: isEdit ? state._key : "",
            })
          );
          UpdateDone();
        }
      } else {
        await dispatch(
          actions.UPSERT_ORAGANIZATION({
            state: state,
            key: isEdit ? state._key : "",
          })
        );

        UpdateDone();
      }
    }
  };

  const checkBedNumberAlreadyExist = async (entityName) => {
    try {
      let params = {
        db_name: "ipmo",
        entity: "Bed",
        filter: `Bed.BedNumber=='${entityName}' && Bed.activestatus==true`,
        return_fields: "Bed",
      };
      let res = await AxiosCall(
        "post",
        `${Config.api_url}/api/read_documents`,
        params
      );

      let result = res?.result;
      return result;
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Box
      style={{
        height: "100%",
        padding: "8px",
        background: "#fff",
        borderRadius: "8px",
      }}
    >
      <Grid
        item
        xs={12}
        lg={12}
        md={12}
        sm={12}
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "8px 0px",
        }}
      >
        {/* <div> */}

        <Typography style={{ fontWeight: "800" }} gutterBottom variant="body1">
          {"Organization Details"}
        </Typography>
        {/* </div> */}
        <FlexRow>
          <Button
            variant="text"
            onClick={() => backButtonClicked()}
            style={{ marginRight: "8px" }}
          >
            Back
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => onSaveCliked()}
          >
            {isEdit ? "Update" : "Save"}
          </Button>
        </FlexRow>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12} md={4} lg={4}>
          <Typography variant="body2" gutterBottom>
            {"Entity Type"}
            <span style={{ color: "red" }}>*</span>
          </Typography>
          <Autocomplete
            // id={`${parent_id}-Entity-Type-autocomplete`}
            options={entityList}
            getOptionLabel={(option) => option.label || ""}
            size={"small"}
            value={entityList.find((l) => l?.label === state?.entityType)}
            onChange={(e, value) => handleChange("entityType", value?.label)}
            disabled={isEdit}
            renderInput={(params) => (
              <TextField
                // id={`${parent_id}-Entity-Type-textField`}
                {...params}
                variant="outlined"
                // error={props.data?.error?.EntityType}
                placeholder={"Select"}
                autoComplete="off"
                size="small"
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4}>
          <Typography variant="body2" gutterBottom>
            {state?.entityType === "Room" || state?.entityType === "Bed"
              ? `${state?.entityType} Number`
              : `${state?.entityType ?? "Entity"} Name`}
            <span style={{ color: "red" }}>*</span>
          </Typography>
          <TextField
            id="outlined-basic"
            variant="outlined"
            fullWidth
            autoComplete="off"
            disabled={isEdit && state?.entityType === "Bed" ? true : false}
            size="small"
            placeholder={"Name"}
            value={state?.entityName}
            onChange={(e) => handleChange("entityName", e.target.value)}
          />
        </Grid>
        {(state?.entityType === "Facility" ||
          state?.entityType === "Ward" ||
          state?.entityType === "Room" ||
          state?.entityType === "Bed") && (
          <Grid item xs={12} sm={12} md={4} lg={4}>
            <Typography variant="body2" gutterBottom>
              {"Parent Entity"}
              <span style={{ color: "red" }}>*</span>
            </Typography>
            <Autocomplete
              // id={`${parent_id}-Entity-Type-autocomplete`}
              options={parentEntity ?? []}
              getOptionLabel={(option) => option.title}
              size={"small"}
              value={getParentValue(state?.parentEntity) || null}
              disabled={isEdit && state?.entityType === "Bed" ? true : false}
              onChange={(e, value) =>
                handleChange("parentEntity", value?.value)
              }
              renderInput={(params) => (
                <TextField
                  // id={`${parent_id}-Entity-Type-textField`}
                  {...params}
                  variant="outlined"
                  // error={props.data?.error?.EntityType}
                  placeholder={"Select"}
                  autoComplete="off"
                  size="small"
                />
              )}
            />
          </Grid>
        )}

        <Grid item xs={12} sm={12} md={4} lg={4}>
          <Typography variant="body2" gutterBottom>
            {state?.entityType
              ? `${state?.entityType} Description`
              : "Entity Description"}
          </Typography>
          <TextField
            id="outlined-basic"
            variant="outlined"
            fullWidth
            autoComplete="off"
            size="small"
            placeholder={"Description"}
            value={state?.entityDescription ?? ""}
            onChange={(e) => handleChange("entityDescription", e.target.value)}
          />
        </Grid>

        {(state?.entityType === "Room" || state?.entityType === "Bed") && (
          <Grid item xs={12} sm={12} md={4} lg={4}>
            <Typography variant="body2" gutterBottom>
              {`${state?.entityType} Type`}
            </Typography>
            <TextField
              id="outlined-basic"
              variant="outlined"
              fullWidth
              autoComplete="off"
              size="small"
              placeholder={"Type"}
              value={
                state?.entityType === "Room" ? state?.roomType : state?.bedType
              }
              name={state?.entityType === "Room" ? "roomType" : "bedType"}
              onChange={(e) => handleChange(e.target.name, e.target.value)}
            />
          </Grid>
        )}

        {state?.entityType === "Facility" && (
          <Grid item xs={12} sm={12} md={4} lg={4}>
            <Typography variant="body2" gutterBottom>
              {`${state?.entityType} Address`}
            </Typography>
            <TextField
              id="outlined-basic"
              variant="outlined"
              fullWidth
              autoComplete="off"
              size="small"
              placeholder={"Type"}
              value={state?.facilityAddress}
              onChange={(e) => handleChange("facilityAddress", e.target.value)}
            />
          </Grid>
        )}
      </Grid>
    </Box>
  );
};
