import { Box, makeStyles, Paper } from "@material-ui/core";
import React from "react";
import PatientOrdering from "../patientOrdering";
import { KitchenOrderingForPatient } from "./kitchenOrderingForPatient";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: "100%",
    backgroundColor: "#F3F4F7",
    // padding: "8px",
    borderRadius: "0px",
  },
  body: {
    padding: "8px 16px",
    background: theme.palette.background.gray,
    height: "100%",
  },
}));

const KitchenOrdering = () => {
  const classes = useStyles();

  const [showPatientOrders, setShowPatientOrder] = React.useState(false);

  const [selectedPatient, setSelectPatient] = React.useState({});

  const updateSelectedPatient = (l) => {
    let user = {
      Patient: { PatientCategory: l?.PatientCategory?._id },
      BedCode: l?.beddetails?._id,
      PatientCode: l?._id,
    };
    setSelectPatient(user);
    updatepage();
  };

  const updatepage = () => {
    setShowPatientOrder(true);
  };

  const backToSearch = () => {
    setShowPatientOrder(false);
  };
  return (
    <>
      <Paper className={classes.root}>
        <Box className={classes.body}>
          {showPatientOrders ? (
            <PatientOrdering
              fromKitchen={true}
              selectedPatient={selectedPatient}
              backToSearch={backToSearch}
            />
          ) : (
            <KitchenOrderingForPatient
              selectedPatient={selectedPatient}
              updateSelectedPatient={updateSelectedPatient}
              updatepage={updatepage}
              backT0Search={backToSearch}
            />
          )}
        </Box>
      </Paper>
    </>
  );
};

export default KitchenOrdering;
