import {
  Typography,
  Grid,
  TextField,
  CircularProgress,
} from "@material-ui/core";
import React from "react";
import { RequiredField } from "../common";
import { useDispatch, useSelector } from "react-redux";
import { actions } from "ipmo_v_dbinder";
import { Autocomplete } from "@material-ui/lab";
import DateFnsUtils from "@date-io/date-fns";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
const PractitionerForm = (props) => {
  // console.log("practitionerForm", props);
  const { edit, editData } = props;
  const dispatch = useDispatch();
  const EntityType = useSelector(
    (state) => state?.personSlice.facilityList?.data
  );
  const organizationSlice = useSelector(
    (state) => state?.personSlice.orgname?.data
  );
  const Gender = useSelector((state) => state?.personSlice.gender_master?.data);
  const Prefix = useSelector((state) => state?.personSlice.prefix_master?.data);
  const roleList = useSelector((state) => state?.rolesSlice?.role_read?.data);
  // console.log("rolelist", roleList, organizationSlice, EntityType);
  // const [imageFile, setImageFile] = React.useState({
  //   file: "",
  // });
  // const imageHandle = (event) => {
  //   setImageFile({ file: URL.createObjectURL(event.target.files[0]) });
  // };
  const genderValue = React.useRef(edit ? editData[0]?.gender?._id : "");
  const name = React.useRef({
    use: "",
    text: edit ? editData[0]?.name[0]?.text : "",
    family: edit ? editData[0]?.name[0]?.family : "",
    given: edit ? editData[0]?.name[0]?.given : "",
    prefix: edit ? editData[0]?.name[0]?.prefix : "",
    suffix: edit ? editData[0]?.name[0]?.suffix : "",
    period: [],
    id: 0,
  });

  const telecom = React.useRef({
    system: "",
    value: edit ? editData[0]?.telecom[0]?.value : "",
    use: "",
    rank: "",
    period: [],
    id: 0,
  });
  const entityName = React.useRef(edit ? { id: editData[0]?.orgType } : []);
  const roleID = React.useRef(edit ? editData[0]?.roleid[0] : {});
  // const [photo, setPhotoDetails] = React.useState({
  //   date: "",
  //   url: "",
  //   id: 0,
  //   fileName: "",
  //   fileid: "",
  //   filetype: "",
  // });
  const address = React.useRef({
    country: "",
    Type: "",
    city: "",
    line: "",
    use: "",
    postalCode: "",
    state: "",
  });
  const dateOfBirth = React.useRef(
    edit
      ? editData[0]?.birthDay.length === 0
        ? null
        : new Date(editData[0]?.birthDay.split("/").reverse().join("-"))
      : null
  );

  const OrgID = React.useRef(edit ? editData[0]?.OrgID[0] : null);

  // const [parentEntity, setParent] = React.useState([]);
  // console.log("name.current", entityName.current, OrgID.current);
  React.useLayoutEffect(() => {
    dispatch(actions.ROLE_READ());
    // dispatch(actions.ORGANIZATION_GET_LEVEL_CARE());
    dispatch(actions.GENDERMASTER());
    dispatch(actions.PREFIXMASTER());
    dispatch(actions.SURFFIXMASTER());
    // dispatch(actions.GET_PARENT_ENTITY("Facility"));
    dispatch(actions.ORGNAME());
    if (edit) {
      handleChange(OrgID.current);
    } else {
      getParent(undefined);
    }
  }, [dispatch, edit]);
  const getParent = async (type) => {
    // console.log(type);
    dispatch(actions.FACILITYLIST(type)); // console.log("getParent", res.payload.data);
    // setParent(res.payload.data);
    // console.log("parent", parent);
  };
  const handleChange = (value) => {
    getParent(value?.id);
  };
  // console.log({ parentEntity });
  const forUpdate = () => {
    let valll = {
      name: [
        {
          use: name.current.use,
          text: name.current.text,
          family: name.current.family,
          given: name.current.given,
          prefix: name.current.prefix,
          suffix: name.current.suffix,
          period: [],
          id: 0,
        },
      ],
      telecom: [
        {
          system: telecom.current.system,
          value: telecom.current.value,
          use: telecom.current.use,
          rank: telecom.current.rank,
          period: [],
          id: 0,
        },
      ],
      gender: genderValue.current,
      birthDay:
        dateOfBirth.current !== null &&
        dateOfBirth.current !== "Invalid Date" &&
        new Date().getTime() >= dateOfBirth.current?.getTime()
          ? `${
              new Date(dateOfBirth.current).getDate().toString().length === 1
                ? `0${new Date(dateOfBirth.current).getDate()}`
                : new Date(dateOfBirth.current).getDate()
            }/${
              (new Date(dateOfBirth.current).getMonth() + 1).toString()
                .length === 1
                ? `0${new Date(dateOfBirth.current).getMonth() + 1}`
                : new Date(dateOfBirth.current).getMonth() + 1
            }/${new Date(dateOfBirth.current).getFullYear()}`
          : dateOfBirth.current === null
          ? ""
          : new Date().getTime() >= dateOfBirth.current?.getTime()
          ? ""
          : "err",
      address: [
        {
          country: address.current.country,
          Type: address.current.Type,
          city: address.current.city,
          line: address.current.line,
          use: address.current.use,
          postalCode: address.current.postalCode,
          state: address.current.state,
        },
      ],
      photo: [
        {
          date: "",
          url: "",
          id: 0,
          fileName: "test",
          fileid: "101",
          filetype: "png",
        },
      ],
      active: true,
      link: [
        {
          isPractitioner: false,
          asurrance: "",
          patientID: [],
          PractitionerID: [],
        },
      ],
      Id: edit ? editData[0].Id : 0,
      RelatedPersonID: edit ? editData[0].RelatedPersonID : [],
      OrgID: [OrgID.current],
      alias: "",
      usertype: "",
      orgType: entityName.current?.id,
      roleid: [roleID.current],
      keycloackid: edit ? editData[0].keycloackid : "",
    };
    // console.log("prefic", valll, entityName.current.id);
    return valll;
  };
  return (
    <React.Fragment>
      {!props.loading && (
        <div
          style={{
            textAlign: "center",
            paddingTop: "90px",
            position: "absolute",
            transform: "translate(-50%, -50%)",
            left: "50%",
            top: "50%",
            zIndex: "2",
          }}
        >
          <CircularProgress />
        </div>
      )}

      <Grid
        container
        style={{
          height: "100%",
          // backgroundColor: "white",
          padding: "10px",
          borderRadius: "4px",
        }}
      >
        <Grid
          container
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          spacing={2}
          style={{ padding: "10px", borderRadius: "8px" }}
        >
          <Grid item xl={3} lg={3} md={3} sm={6} xs={12}>
            <Typography noWrap>Prefix</Typography>

            <Autocomplete
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Prefix"
                  variant="outlined"
                />
              )}
              options={Prefix ?? []}
              getOptionLabel={(option) => option.display ?? []}
              value={
                Prefix.find((l) => l.display === name.current.prefix) || ""
              }
              onChange={(e, newvalue) => {
                forUpdate();
                name.current = {
                  ...name.current,
                  prefix: newvalue?.display,
                };
                props.sendData("prefix", newvalue, forUpdate());
              }}
              fullWidth
              variant="outlined"
              size="small"
            />
          </Grid>
          <Grid item xl={3} lg={3} md={3} sm={6} xs={12}>
            <Typography noWrap>
              First Name
              <RequiredField color="red" />
            </Typography>
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              value={name.current?.text}
              onChange={(e) => {
                name.current = {
                  ...name.current,
                  text: e.target.value,
                };
                props.sendData("name", name.current, forUpdate());
              }}
            />
          </Grid>
          <Grid item xl={3} lg={3} md={3} sm={6} xs={12}>
            <Typography noWrap>Middle Name</Typography>
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              value={name.current?.family}
              onChange={(e) => {
                name.current = {
                  ...name.current,
                  family: e.target.value,
                };
                props.sendData("name", name.current, forUpdate());
              }}
            />
          </Grid>
          <Grid item xl={3} lg={3} md={3} sm={6} xs={12}>
            <Typography noWrap>
              Last Name
              <RequiredField color="red" />
            </Typography>

            <TextField
              fullWidth
              variant="outlined"
              size="small"
              value={name.current?.given}
              onChange={(e) => {
                name.current = {
                  ...name.current,
                  given: e.target.value,
                };
                props.sendData("name", name.current, forUpdate());
              }}
            />
          </Grid>
          <Grid item xl={4} lg={4} md={4} sm={6} xs={12}>
            <Typography>
              Gender
              <RequiredField color="red" />
            </Typography>

            <Autocomplete
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Gender"
                  variant="outlined"
                />
              )}
              value={Gender.find((l) => l._id === genderValue.current) || ""}
              options={Gender ?? []}
              getOptionLabel={(option) => option.display ?? []}
              fullWidth
              variant="outlined"
              size="small"
              onChange={(e, newvalue) => {
                genderValue.current = newvalue?._id;
                props.sendData("gender", newvalue, forUpdate());
              }}
            />
          </Grid>
          <Grid item xl={4} lg={4} md={4} sm={6} xs={12}>
            <Typography noWrap>Date of Birth</Typography>
            {/* <TextField
                  size="small"
                  variant="outlined"
                  id="date"
                  type="date"
                  fullWidth
                  value={dateOfBirth.current ?? ""}
                  InputProps={{
                    inputProps: {
                      min: "1900-12-01",
                      max: todaydate(),
                    },
                  }}
                  onChange={(e) => {
                    dateOfBirth.current = e.target.value;
                    // console.log("birthDayd", dateOfBirth);
                    props.sendData(
                      "birthDay",
                      dateOfBirth.current,
                      forUpdate()
                    );
                  }}
                  // defaultValue={new Date().toISOString().slice(0, 10)}
                  // className={classes.textField}
                  InputLabelProps={{
                    shrink: true,
                  }}
                /> */}
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                disableFuture={true}
                maxDateMessage="Future Date Not Available"
                fullWidth
                size="small"
                value={dateOfBirth.current}
                placeholder="dd-mm-yyyy"
                onError={(e) => forUpdate(e)}
                onChange={(e) => {
                  dateOfBirth.current = e;
                  props.sendData("birthDay", dateOfBirth.current, forUpdate());
                }}
                maxDate={new Date()}
                format="dd/MM/yyyy"
                autoOk
                variant="inline"
                inputVariant="outlined"
                InputAdornmentProps={{ position: "start" }}
              />
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid item xl={4} lg={4} md={4} sm={6} xs={12}>
            <Typography>
              Email
              <RequiredField color="red" />
            </Typography>
            <TextField
              disabled={edit ? true : false}
              fullWidth
              variant="outlined"
              size="small"
              value={telecom.current.value}
              onChange={(e) => {
                telecom.current = {
                  ...telecom.current,
                  value: e.target.value,
                };
                props.sendData("telecom", telecom.current, forUpdate());
              }}
            />
          </Grid>
          <Grid item xl={4} lg={4} md={4} sm={6} xs={12}>
            <Typography>
              Select Role
              <RequiredField color="red" />
            </Typography>

            <Autocomplete
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Select Role"
                  variant="outlined"
                />
              )}
              options={
                roleList.filter(
                  (e) => e.rolename !== "Patient" && e.status === true
                ) ?? []
              }
              value={
                roleList.find((l) => l?.roleid === roleID.current?.roleid) || ""
              }
              onChange={(e, newvalue) => {
                roleID.current = newvalue;
                props.sendData("roleid", newvalue, forUpdate());
              }}
              getOptionLabel={(option) => option?.rolename ?? []}
              fullWidth
              variant="outlined"
              size="small"
            />
          </Grid>
          <Grid item xl={4} lg={4} md={4} sm={6} xs={12}>
            <Typography>
              Select Entity
              <RequiredField color="red" />
            </Typography>

            {/* <Autocomplete
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder="Select Entity"
                          variant="outlined"
                        />
                      )}
                      options={organizationSlice ?? []}
                      // value={OrgID}
                      value={OrgID}
                      onChange={(e, newvalue) => {
                        console.log(newvalue);
                        setOrgId(newvalue);
                        props.sendData("OrgID", newvalue);
                      }}
                      getOptionLabel={(option) => option.title ?? []}
                      fullWidth
                      variant="outlined"
                      size="small"
                    /> */}
            <Autocomplete
              // id={`${parent_id}-Entity-Type-autocomplete`}
              options={organizationSlice}
              getOptionLabel={(option) => option.name || ""}
              size={"small"}
              value={
                organizationSlice.find((l) => l.id === OrgID.current?.id) ?? []
              }
              onChange={(e, value) => {
                handleChange(value);
                OrgID.current = value;
                props.sendData("OrgID", value, forUpdate());
              }}
              renderInput={(params) => (
                <TextField
                  // id={`${parent_id}-Entity-Type-textField`}
                  {...params}
                  variant="outlined"
                  // error={props.data?.error?.EntityType}
                  placeholder={"Select Entity"}
                  autoComplete="off"
                  size="small"
                />
              )}
            />
          </Grid>
          <Grid item xl={4} lg={4} md={4} sm={6} xs={12}>
            <Typography>
              Entity Name
              <RequiredField color="red" />
            </Typography>
            <Autocomplete
              // id={`${parent_id}-Entity-Type-autocomplete`}
              options={entityName !== null && EntityType}
              getOptionLabel={(option) => option.name}
              size={"small"}
              onChange={(e, value) => {
                entityName.current = value;
                props.sendData("orgType", value?.id, forUpdate());
              }}
              value={
                EntityType.find((l) => l.id === entityName?.current?.id) ?? []
              }
              renderInput={(params) => (
                <TextField
                  // id={`${parent_id}-Entity-Type-textField`}
                  {...params}
                  variant="outlined"
                  // error={props.data?.error?.EntityType}
                  placeholder={"Entity Name"}
                  autoComplete="off"
                  size="small"
                />
              )}
            />
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default PractitionerForm;
