import { Grid } from "@material-ui/core";
import React from "react";
import { PatientCard } from "../../components";
import StarsIcon from "@material-ui/icons/Stars";
import HotelIcon from "@material-ui/icons/Hotel";
import PersonIcon from "@material-ui/icons/Person";
export const ListOfCards = (props) => {
  const { patientList, updateSelectedPatient } = props;

  const getFullName = (l) => {
    return `${l.PatientFName} ${l.PatientMName} ${l.PatientLName} `;
  };

  const onCardClicked = (l) => {
    updateSelectedPatient(l);
  };
  return (
    <>
      <Grid container spacing={3}>
        {patientList?.map((item, index) => (
          <PatientCard
            onCardClicked={onCardClicked}
            data={item}
            variant="1"
            key={`${index}`}
            field1={{ title: getFullName(item) }}
            field2={{ title: item?.status?.display }}
            field3={{
              title: item?.PatientMRN,
              icon: <PersonIcon fontSize="small" />,
            }}
            field4={{
              title: item?.beddetails?.BedNumber,
              icon: <HotelIcon fontSize="small" />,
            }}
            field5={{
              title: item?.PatientCategory?.display,
              icon: <StarsIcon fontSize="small" />,
            }}
          />
        ))}
      </Grid>
    </>
  );
};
