import {
  alpha,
  Box,
  Dialog,
  Grid,
  makeStyles,
  Slide,
  Typography,
} from "@material-ui/core";
import React from "react";
import { AxiosCall } from "../../api";
import { FoodCard } from "../../components";
import Config from "../../config";
import { RatingForm } from "./rating";
import UpdateIcon from "@material-ui/icons/Update";

const useStyles = makeStyles((theme) => ({
  dialogRoot: {
    "& .MuiDialog-scrollPaper": {
      display: "flex",
      alignItems: "flex-end",
      justifyContent: "center",
    },
    "& .MuiDialog-paperFullScreen": {
      width: "100%",
      height: "85%",
      margin: "0px",
      maxWidth: "100%",
      maxHeight: "none",
      borderRadius: "16px 16px 0 0",
    },
  },
  flexBox: {
    display: "flex",
    alignItems: "center",
    marginBottom: theme.spacing(1),
  },
  textResponsive: {
    flex: "1 1 auto",
    marginLeft: theme.spacing(1),
    [theme.breakpoints.down("md")]: {
      fontSize: "1rem !important",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "1rem !important",
    },
  },
  group: {
    marginLeft: "8px !important",
    paddingLeft: 12,
    borderLeft: `1px dashed ${alpha(theme.palette.text.primary, 0.4)}`,
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const OrderHistory = (props) => {
  const classes = useStyles();
  const { orderHistory = [], user, getOrderDetails } = props;
  const [open, setOpen] = React.useState(false);
  const [selectItem, setSelectedItem] = React.useState({});
  const [ratingItems, setRatingItems] = React.useState([]);
  const [ratingMaster, setRatingMaster] = React.useState([]);

  React.useEffect(() => {
    getRateValueFromCodingMaster();
  }, []);

  const getRateValueFromCodingMaster = async () => {
    let params = {
      db_name: Config.dbname,
      entity: "CodeableConceptMaster",
      filter:
        "CodeableConceptMaster.Type=='FOODRATING' &&CodeableConceptMaster.status==true && CodeableConceptMaster.activestatus==true",
      sort: "CodeableConceptMaster.text",
      return_fields:
        "MERGE(CodeableConceptMaster,{coding:FIRST(FOR cod IN CodeableConceptMaster.coding RETURN DOCUMENT(cod))})",
    };
    let res = await AxiosCall(
      "post",
      `${Config.api_url}/api/read_documents`,
      params
    );

    let result = res?.result;

    setRatingMaster(result);
  };

  const openModel = (value, isEdit) => {
    setOpen(true);
    if (isEdit) {
      setRatingItems(value?.feedBack[0]?.Feedback);
      setSelectedItem({ ...value, isEdit });
    } else {
      let addontoarr =
        value.Addon.trim().length > 0
          ? value.Addon.split(",").map((l) => {
              return {
                title: l,
                rating: { value: 0, label: "" },
                feedback: "",
                image: "",
              };
            })
          : [];
      let list = [
        {
          title: value?.OrderItemcode?.MealCode?.MealDescription,
          rating: { value: 0, label: "" },
          feedback: "",
          image: "",
        },
        ...addontoarr,
      ];
      setRatingItems(list);
      setSelectedItem({ ...value, isEdit });
    }
  };

  const handleClose = (complete) => {
    setOpen(false);
    if (complete) {
      getOrderDetails();
    }
  };

  const getDateTOString = (value) => {
    const d = `${value}T00:00:00Z`;
    const date = new Date(d);
    return date.toDateString();
  };
  return (
    <Grid container spacing={1}>
      {orderHistory[0].Meals?.map((l, index) => (
        <Grid item xs={12} lg={12} md={12} sm={12}>
          <Box className={classes.flexBox}>
            <UpdateIcon fontSize="small" color="primary" />
            <Typography
              variant="body1"
              display="block"
              color="primary"
              className={classes.textResponsive}
            >
              {getDateTOString(l?.OrderDate)}
            </Typography>
          </Box>

          <Grid container spacing={1} className={classes.group}>
            {l?.mealDetails?.map((menu, i) => (
              <FoodCard
                key={`${i}`}
                title={menu?.OrderItemcode?.MealCode?.MealDisplayName}
                image={`${process.env.REACT_APP_IMAGE_UPLOAD_PROTOCAL}://${process.env.REACT_APP_IMAGE_UPLOAD_HOST}/${process.env.REACT_APP_DB}/${menu?.OrderItemcode?.MealCode?.MealPic}`}
                subTitle={menu?.MealCategoryName}
                description={menu?.OrderItemcode?.MealCode?.MealDescription}
                content={menu?.Addon ?? ""}
                status={menu?.status}
                feedBack={menu?.feedBack}
                mealType={menu?.MealCategoryName}
                openModel={openModel}
                data={menu}
                setSelectedItem={setSelectedItem}
              />
            ))}
          </Grid>
        </Grid>
      ))}
      {/* {orderHistory?.map((menu, i) => (
        <FoodCard
          key={`${i}`}
          title={menu?.MealCode?.MealDisplayName}
          image={`${process.env.REACT_APP_IMAGE_UPLOAD_PROTOCAL}://${process.env.REACT_APP_IMAGE_UPLOAD_HOST}/${process.env.REACT_APP_DB}/${menu?.MealCode?.MealPic}`}
          subTitle={menu?.OrderDate}
          content={menu?.Addon ?? ""}
          status={menu?.CurrantStatus?.states}
          mealType={menu?.OrderOtherDetails}
          openModel={openModel}
          data={menu}
          setSelectedItem={setSelectedItem}
        />
      ))} */}
      <Dialog
        open={open}
        TransitionComponent={Transition}
        // keepMounted
        fullScreen
        onClose={handleClose}
        className={classes.dialogRoot}
      >
        <RatingForm
          handleClose={handleClose}
          selectItem={selectItem}
          ratingItems={ratingItems}
          user={user}
          ratingMaster={ratingMaster}
          setRatingItems={setRatingItems}
        />
      </Dialog>
    </Grid>
  );
};
