import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import { DrawerProps, LocalStorageKeys } from "../../../utils";
import NotificationsNoneOutlinedIcon from "@material-ui/icons/NotificationsNoneOutlined";
import Badge from "@material-ui/core/Badge";
import { TbLogout } from "react-icons/tb";
import {
  List,
  ListItemIcon,
  ListItem,
  ListItemText,
  useTheme,
  useMediaQuery,
  Tooltip,
  Divider,
} from "@material-ui/core";
import Popover from "@material-ui/core/Popover";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import { useHistory } from "react-router-dom";
import { DrawerContext } from "../../../contexts";
import ListNotification from "./listNotification";
import Config from "../../../config";
import { AxiosCall } from "../../../api";

const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
    zIndex: theme.zIndex.drawer + 1,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    background: theme.palette.appBar.main,
    // borderBottom: "1px solid #e0e0e0",
  },
  title: {
    display: "block",

    textTransform: "capitalize",
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.8rem !important",
    },
  },
  caption: {
    display: "block",
    marginLeft: theme.spacing(1),
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.6rem !important",
    },
  },
  titleContainer: {
    display: "flex",
    alignItems: "center",
  },
  menuIcon: {
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  userProfilecard: {
    display: "flex",
    alignItems: "center",
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  profilePic: {
    marginLeft: "8px",
  },
  notificationdiv: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  notifications: {},
}));

export const TopNavBar = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const Theme = useTheme();
  const sm = useMediaQuery(Theme.breakpoints.down("sm"));
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [notificationMsg, setNotificationMsg] = React.useState([]);
  const [user, setUser] = React.useState({});
  const drawerContext = React.useContext(DrawerContext);
  const id = open ? "simple-popover" : undefined;
  React.useEffect(() => {
    notify();
    const interval = setInterval(
      () => notify(),
      Number(localStorage.getItem("TriggerTiming") ?? "300000")
    );
    return () => {
      clearInterval(interval);
    };
  }, []);
  const handleClose = () => {
    setAnchorEl(null);
  };

  React.useLayoutEffect(() => {
    getPersonLoggedin(
      localStorage.getItem("kclkid"),
      atob(localStorage.getItem("RBAC"))
    );
  }, []);

  const getPersonLoggedin = async (id, role) => {
    // debugger;
    let params = {
      db_name: Config.dbname,
      entity: process.env.REACT_APP_ENTITY_PERSON,
      filter: `${process.env.REACT_APP_ENTITY_PERSON}.keycloackid=='${id}' && ${process.env.REACT_APP_ENTITY_PERSON}.activestatus==true`,
      return_fields:
        role === "5"
          ? "{rolename:Person.roleid[0].rolename,person:MERGE(FOR Bed in Bed Filter Bed.BedNumber==document(Person.telecom[0]).value && Bed.activestatus==true RETURN MERGE(FOR PatientCheckIn in PatientCheckIn FILTER PatientCheckIn.BedCode==Bed._id && PatientCheckIn.activestatus==true  RETURN MERGE({text:FIRST(FOR Patient IN Patient FILTER Patient._id==PatientCheckIn.PatientCode && Patient.activestatus==true RETURN Patient.PatientFName)})))}"
          : "{rolename:Person.roleid[0].rolename,person:UNSET(document(Person.name[0]),'_key','_id','_rev','createddate','createdby','activestatus','updatedby','updatedate','family','id','given','prefix','use','person','period')}",
    };

    let res = await AxiosCall(
      "post",
      `${Config.api_url}/api/read_documents`,
      params
    );
    let result = res?.result[0];
    setUser(result);
  };

  const handleLogout = () => {
    localStorage.removeItem("aTclk");
    localStorage.removeItem("rTclk");
    localStorage.removeItem("kclkid");
    localStorage.removeItem("RBAC");
    sessionStorage.removeItem("user");
    localStorage.removeItem("user");
    localStorage.removeItem("TriggerTiming");
    localStorage.removeItem("access_token");
    // localStorage.removeItem("RoleId")
    history.push("/");
  };

  const notify = async () => {
    // debugger;
    let myHeaders = new Headers();
    myHeaders.append("content-type", "application/json");
    fetch(`${Config.api_url}/api/read_documents`, {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify({
        db_name: Config.dbname,
        entity: process.env.REACT_APP_notify_ENTITY,
        filter: `${process.env.REACT_APP_notify_ENTITY}.activestatus==true`,
        return_fields:
          "keep(Notification,'id','_id','Notification','Notification_type','Notification_count','Patient_id','Mealorderid','createddate')",
        sort: "Notification.createddate desc",
      }),
    })
      .then((res) => res.json())
      .then((result) => {
        let Res = [...result.result];
        //   result.result.forEach((e)=>{
        //     Res.push({
        //       image :'' ,
        //       message : e.Notification,
        //       // detailPage : "",
        //       receivedTime:'12h ago',
        //       Notification_count:e.Notification_count,
        //       _id:e._id
        //     })
        //     setNotificationMsg(Res)
        // console.log(Res);
        //   })
        // console.log(Res);
        setNotificationMsg(Res);
      })
      .catch((err) => console.log(err));
  };

  const onNotifyclick = (data) => {
    drawerContext.setDrawer({
      ...drawerContext,
      open: true,
      direction: DrawerProps.direction.right,
      variant: DrawerProps.variant.temporary,
      component: <ListNotification data={data} countnotify={notify} />,
      onClose: () => onDrawerClosed(data),
    });
  };
  const onDrawerClosed = async (data) => {
    // debugger;
    drawerContext.setDrawer({
      ...drawerContext,
      open: false,
      direction: DrawerProps.direction.right,
    });
  };

  return (
    <div className={classes.grow}>
      <AppBar position="static" className={classes.appBar}>
        <Toolbar>
          <div className={classes.titleContainer}>
            {sm ? (
              <img
                alt="icon"
                src="images/KPJLOGO.png"
                style={{ width: "45px", height: "30px" }}
              />
            ) : (
              <img
                alt="icon"
                src="images/KPJLOGO.png"
                style={{ width: "65px", height: "50px" }}
              />
            )}
            <div>
              <Typography className={classes.title} variant="body1" noWrap>
                {"MEAL ORDER"}
              </Typography>
              <Typography variant="caption" className={classes.caption}>
                {`v ${localStorage.getItem(LocalStorageKeys.version)}`}
              </Typography>
            </div>
          </div>

          <div className={classes.grow} />
          <div className={classes.notificationdiv}>
            {atob(localStorage.getItem("RBAC")) === "4" && (
              <IconButton
                onClick={() => onNotifyclick(notificationMsg)}
                style={{ color: "#fff" }}
              >
                <Badge
                  badgeContent={
                    notificationMsg.filter((l) => l?.Notification_count === 0)
                      .length
                  }
                  color="secondary"
                >
                  <NotificationsNoneOutlinedIcon />
                </Badge>
              </IconButton>
            )}
          </div>

          <div className={classes.userProfilecard}>
            <div>
              <Typography
                className={classes.title}
                variant="body1"
                component={"h6"}
                noWrap
              >
                {user?.person?.text?.trim().length > 0
                  ? user?.person?.text
                  : user?.rolename}
              </Typography>
              {user?.person?.text?.trim().length > 0 && (
                <Typography
                  // className={classes.title}
                  variant="caption"
                  noWrap
                >
                  {user?.rolename}
                </Typography>
              )}
            </div>
            {/* <Avatar className={classes.profilePic}>
                {atob(sessionStorage.getItem("user")).charAt(0).toUpperCase()}
              </Avatar> */}
          </div>
          <Divider
            flexItem
            orientation="vertical"
            style={{ margin: "16px 4px", width: "2px", background: "#fff" }}
          />

          {sm ? (
            <IconButton
              style={{ color: "#fff" }}
              onClick={() => handleLogout()}
            >
              <TbLogout />
            </IconButton>
          ) : (
            <Tooltip title="Logout" arrow>
              <IconButton
                style={{ color: "#fff" }}
                onClick={() => handleLogout()}
              >
                <TbLogout />
              </IconButton>
            </Tooltip>
          )}
        </Toolbar>
      </AppBar>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <List>
          <ListItem Button onClick={() => handleLogout()}>
            <ListItemIcon style={{ width: "30px" }}>
              <ExitToAppIcon />
            </ListItemIcon>
            {!sm && <ListItemText primary="Logout" />}
          </ListItem>
        </List>
      </Popover>
    </div>
  );
};
