import { Routes } from "../router/routes";
import ApartmentIcon from "@material-ui/icons/Apartment";
import AssignmentTurnedInOutlinedIcon from "@material-ui/icons/AssignmentTurnedInOutlined";
import AssessmentOutlinedIcon from "@material-ui/icons/AssessmentOutlined";
import AccountBalanceOutlinedIcon from "@material-ui/icons/AccountBalanceOutlined";
import DynamicFeedIcon from "@material-ui/icons/DynamicFeed";
import FastfoodRoundedIcon from "@material-ui/icons/FastfoodRounded";
import HowToRegRoundedIcon from "@material-ui/icons/HowToRegRounded";
import AssignmentTurnedInIcon from "@material-ui/icons/AssignmentTurnedIn";
import { MdOutlineFoodBank } from "react-icons/md";
import HomeIcon from "@material-ui/icons/Home";
/**
 * Object with role as key and value, which is used for
 * comparison of role in different place.
 */
export const UserRoles = {
  role: "role",
};

/**
 * Object which has the proper name of all the role
 * used in the application.
 */
export let UserRolesName = {
  role: "Role",
};

/**
 * Object which has the different themes used in
 * the application.
 */
export let Themes = {
  default: "default",
  dark: "dark",
};

/**
 * Object which has the different props for the Alert Component (/src/component/alert)
 * which is used via AlertContext (/src/contexts) and provided at /src/App.alert.js.
 */
export let AlertProps = {
  vertical: {
    top: "top",
    bottom: "bottom",
  },
  horizontal: {
    left: "left",
    right: "right",
    center: "center",
  },
  severity: {
    success: "success",
    error: "error",
    warning: "warning",
    info: "info",
  },
};

/**
 * Object which has the different props for the Drawer Component (/src/App.drawer.js)
 * which is used via DrawerContext (/src/contexts) and provided at /src/App.drawer.js.
 */
export const DrawerProps = {
  direction: {
    top: "top",
    bottom: "bottom",
    left: "left",
    right: "right",
  },
  variant: {
    permanent: "permanent",
    persistent: "persistent",
    temporary: "temporary",
  },
};

/**
 * Object has the key and value pair of all the keys which
 * are used to store some values in the local storage.
 */
export let LocalStorageKeys = {
  authToken: "auth_token",
  version: "version",
};

/**
 * Object has the key and value pair of all the HTTP method
 * used for an network call.
 */
export let NetWorkCallMethods = {
  get: "GET",
  post: "POST",
  put: "PUT",
  delete: "DELETE",
  update: "UPDATE",
};

/* This list page with icon (icon can't be stored in session or db) */
export let repolist = [
  {
    id: 0,
    name: "Home",
    repo: "Home",
    icon: <HomeIcon fontSize={"small"} />,
    routePath: Routes.home,
  },
  {
    id: 1,
    name: "Organization",
    repo: "Organization",
    icon: <ApartmentIcon fontSize={"small"} />,
    routePath: Routes.Organization,
  },
  {
    id: 2,
    name: "Permission Management",
    repo: "User Management",
    icon: <AccountBalanceOutlinedIcon fontSize={"small"} />,
    routePath: Routes.repositry,
  },
  {
    id: 3,
    name: "Task And Document",
    repo: "Task And Document",
    icon: <AssignmentTurnedInOutlinedIcon fontSize={"small"} />,
    routePath: Routes.taskdocument,
  },
  {
    id: 4,
    name: "Report Viewer",
    repo: "Report Viewer",
    icon: <AssessmentOutlinedIcon fontSize={"small"} />,
    routePath: Routes.reportConfig,
  },
  {
    id: 6,
    name: "Order Catalog",
    repo: "Order Menu",
    icon: <FastfoodRoundedIcon fontSize={"small"} />,
    routePath: Routes.menu,
  },
  {
    id: 7,
    name: "General Masters",
    repo: "General Master",
    icon: <DynamicFeedIcon fontSize={"small"} />,
    routePath: Routes.generalMaster,
  },
  {
    id: 8,
    name: "Visit Registration",
    repo: "Patient Check-in",
    icon: <HowToRegRoundedIcon fontSize={"small"} />,
    routePath: Routes.patientRegistration,
  },
  {
    id: 9,
    name: "Task Viewer",
    repo: "Order Viewer",
    icon: <AssignmentTurnedInIcon fontSize={"small"} />,
    routePath: Routes.taskViewer,
  },
  {
    id: 10,
    name: "Order Configurator",
    repo: "Order Configurator",
    icon: <MdOutlineFoodBank size={"1.3rem"} />,
    routePath: Routes.orderConfigurator,
  },
  {
    id: 11,
    name: "Menu Ordering",
    repo: "Kitchen Ordering",
    icon: <FastfoodRoundedIcon fontSize={"small"} />,
    routePath: Routes.kitchenMenu,
  },
];

/* Responsive Resolution sizes of devices */
export const size = {
  mobileXS: "280px",
  mobileS: "320px",
  mobileM: "375px",
  mobileL: "425px",
  tablet: "768px",
  laptop: "1024px",
  laptopL: "1440px",
  desktop: "2560px",
};

/*max and min Resolution sizes of devices */
export const device = {
  mobileXS: `(min-width: ${size.mobileXS})`,
  mobileS: `(min-width: ${size.mobileS})`,
  mobileM: `(min-width: ${size.mobileM})`,
  mobileL: `(min-width: ${size.mobileL})`,
  tablet: `(min-width: ${size.tablet})`,
  laptop: `(min-width: ${size.laptop})`,
  laptopL: `(min-width: ${size.laptopL})`,
  desktop: `(min-width: ${size.desktop})`,
  desktopL: `(min-width: ${size.desktop})`,
};
