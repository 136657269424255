import React from "react";
import OrderForm from "../../components/orderconfigform/orderform";
import parse from "html-react-parser";
import {
  Button,
  Grid,
  TextField,
  Paper,
  Tab,
  Tabs,
  Typography,
  Card,
  CardContent,
  IconButton,
  useTheme,
  Dialog,
  Divider,
} from "@material-ui/core";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { Autocomplete } from "../../components";
import { makeStyles } from "@material-ui/core/styles";
import { actions } from "ipmo_v_dbinder";
import EditIcon from "@material-ui/icons/Edit";
import axios from "axios";

const useStyles = makeStyles((theme) => ({
  root: {
    // maxWidth: 250,
    // minHeight: 202,
    height: "90%",
    [theme.breakpoints.up("md")]: {
      // maxHeight: 150,
    },
    [theme.breakpoints.down("md")]: {
      fontSize: 120,
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: 120,
    },
  },

  media: {
    height: 100,
  },
  textResponsive: {
    [theme.breakpoints.up("md")]: {
      fontSize: "1rem !important",
    },
    [theme.breakpoints.down("md")]: {
      fontSize: "0.8rem !important",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.465rem !important",
    },
  },
  RichtextResponsive: {
    [theme.breakpoints.up("md")]: {
      fontSize: "0.8rem !important",
    },
    [theme.breakpoints.down("md")]: {
      fontSize: "0.8rem !important",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.465rem !important",
    },
  },
  autoCompleteText: {
    "& .MuiInputBase-root": {
      [theme.breakpoints.up("md")]: {
        fontSize: "1rem !important",
      },
      [theme.breakpoints.down("md")]: {
        fontSize: "0.8rem !important",
      },
      [theme.breakpoints.down("sm")]: {
        fontSize: "0.465rem !important",
      },
    },
  },
  tabText: {
    "& .MuiTab-root": {
      [theme.breakpoints.up("md")]: {
        fontSize: "1rem !important",
      },
      [theme.breakpoints.down("md")]: {
        fontSize: "0.8rem !important",
      },
      [theme.breakpoints.down("sm")]: {
        fontSize: "0.465rem !important",
      },
    },
  },
  iconSize: {
    [theme.breakpoints.up("md")]: {
      fontSize: "24px !important",
    },
    [theme.breakpoints.down("md")]: {
      fontSize: "15px !important",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "10px !important",
    },
  },
  buttonText: {
    "& .MuiButton-textSizeSmall": {
      [theme.breakpoints.up("md")]: {
        fontSize: "1rem !important",
      },
      [theme.breakpoints.down("md")]: {
        fontSize: "0.8rem !important",
      },
      [theme.breakpoints.down("sm")]: {
        fontSize: "0.465rem !important",
      },
    },
  },
  drawerWidth: {
    [theme.breakpoints.up("md")]: {
      width: "80%",
    },
    [theme.breakpoints.down("md")]: {
      fontSize: "0.8rem !important",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.465rem !important",
    },
  },
}));
const OrderConfig = (props) => {
  const dispatch = useDispatch();
  const patientCategorys = useSelector(
    (state) => state?.personSlice.patientcategory?.data
  );
  const mealtype = useSelector((state) => state.personSlice.mealtype?.data);
  const daylist = useSelector((state) => state.personSlice.day?.data);
  const theme = useTheme();
  const classes = useStyles(theme);
  const [openDrawer, setOpenDrawer] = React.useState(false);
  const [value, setValue] = React.useState(0);
  const [mealList, setMealList] = React.useState([]);
  const [mealComp, setMealComp] = React.useState([]);
  const dropDown = React.useRef(null);
  const dropDownForDay = React.useRef(null);
  const [editValue, setEditValue] = React.useState(false);
  const sendEditData = React.useRef([]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const mealCatergoryFunc = () => {
    var MealList = mealtype?.slice().sort((a, b) => {
      return a.id - b.id;
    });
    return MealList;
  };

  const dayListOrder = () => {
    var dayList = daylist?.slice().sort((a, b) => {
      return a.id - b.id;
    });
    return dayList;
  };

  React.useEffect(() => {
    if (
      (dropDown?.current?.length === 0 &&
        dropDownForDay?.current?.length === 0) ||
      dropDown.current === undefined ||
      dropDownForDay.current === undefined
    ) {
      dropDown.current = patientCategorys[0];
      dropDownForDay.current = dayListOrder()[0];
    }
  }, [daylist, patientCategorys]);
  const handleDrawerClose = () => {
    //debugger;
    setOpenDrawer(false);
    if (
      dropDown.current?.length !== 0 &&
      dropDownForDay.current?.length !== 0
    ) {
      callMealMaster();
    }
  };
  // console.log("dropdown", dropDown.current);
  const callMealMaster = React.useCallback((defValue, defValue1) => {
    var myheaders = new Headers();
    myheaders.append("Content-Type", "application/json");
    let data = {
      db_name: process.env.REACT_APP_DB,
      entity: process.env.REACT_APP_ENTITY_MEALMASTER,
      filter: `${process.env.REACT_APP_ENTITY_MEALMASTER}.activestatus==true`,
      return_fields: process.env.REACT_APP_ENTITY_MEALMASTER,
    };
    var requestOptions = {
      method: "POST",
      headers: myheaders,
      body: JSON.stringify(data),
      redirect: "follow",
    };
    fetch(
      `${process.env.REACT_APP_ARANGO_API}/api/read_documents`,
      requestOptions
    )
      .then((result) => result.json())
      .then((response) => {
        var myheaders = new Headers();
        myheaders.append("Content-Type", "application/json");
        let data = {
          db_name: process.env.REACT_APP_DB,
          entity: process.env.REACT_APP_ENITIY_MEALSCHEDULE,
          filter: `${
            process.env.REACT_APP_ENITIY_MEALSCHEDULE
          }.PatientCategoryCode=='${
            defValue ? defValue : dropDown.current?._id
          }' && ${process.env.REACT_APP_ENITIY_MEALSCHEDULE}.MealDay =='${
            defValue1 ? defValue1 : dropDownForDay.current?._id
          }'&& ${process.env.REACT_APP_ENITIY_MEALSCHEDULE}.activestatus==true`,
          return_fields: process.env.REACT_APP_ENITIY_MEALSCHEDULE,
        };
        var requestOptions = {
          method: "POST",
          headers: myheaders,
          body: JSON.stringify(data),
          redirect: "follow",
        };
        fetch(
          `${process.env.REACT_APP_ARANGO_API}/api/read_documents`,
          requestOptions
        )
          .then((result) => result.json())
          .then((res) => {
            let finalList = res.result.flatMap((e) =>
              response.result.filter((v) => v._id === e.MealCode && v)
            );
            // console.log(finalList);
            setMealList(finalList);
            // setMealList(res.result);
          })
          .catch((err) => console.error(err));
      })
      .catch((err) => console.error(err));
  }, []);
  React.useLayoutEffect(() => {
    dispatch(actions.PATIENTCATEGORY());
    dispatch(actions.DAYLIST());
    // console.log("aaaa", dropDown, dropDownForDay);
    dropDown.current = patientCategorys[0];
    dropDownForDay.current = dayListOrder()[0];
    callMealMaster(dropDown.current?._id, dropDownForDay.current?._id);
  }, []);
  function a11yProps(index) {
    return {
      id: `scrollable-force-tab-${index}`,
      "aria-controls": `scrollable-force-tabpanel-${index}`,
    };
  }
  const handleEditFunc = React.useCallback(
    (_id) => {
      setEditValue(true);
      let editData = mealList.filter((i) => i._id === _id);
      // console.log(mealList.filter((i) => i._id === _id));
      var myheaders = new Headers();
      myheaders.append("Content-Type", "application/json");
      let data = {
        db_name: process.env.REACT_APP_DB,
        entity: process.env.REACT_APP_ENITIY_MEALSCHEDULE,
        filter: `${process.env.REACT_APP_ENITIY_MEALSCHEDULE}.MealCode=='${_id}'&& ${process.env.REACT_APP_ENITIY_MEALSCHEDULE}.activestatus==true`,
        return_fields: process.env.REACT_APP_ENITIY_MEALSCHEDULE,
      };
      var requestOptions = {
        method: "POST",
        headers: myheaders,
        body: JSON.stringify(data),
        redirect: "follow",
      };
      fetch(
        `${process.env.REACT_APP_ARANGO_API}/api/read_documents`,
        requestOptions
      )
        .then((result) => result.json())
        .then((res) => {
          editData = { ...editData, ...res.result[0] };
          // console.log("finaledit", editData);
          sendEditData.current = editData;
          setOpenDrawer(true);
          // setMealList(res.result);
        })
        .catch((err) => console.error(err));
    },
    [mealList]
  );
  React.useEffect(() => {
    dispatch(actions.MEALTYPE());
    var myheaders = new Headers();
    myheaders.append("Content-Type", "application/json");
    let data = {
      db_name: process.env.REACT_APP_DB,
      entity: process.env.REACT_APP_ENTITY_MEALMASTER,
      filter: `${process.env.REACT_APP_ENTITY_MEALMASTER}.activestatus==true`,
      return_fields: process.env.REACT_APP_ENTITY_MEALMASTER,
    };
    var requestOptions = {
      method: "POST",
      headers: myheaders,
      body: JSON.stringify(data),
      redirect: "follow",
    };
    fetch(
      `${process.env.REACT_APP_ARANGO_API}/api/read_documents`,
      requestOptions
    )
      .then((result) => result.json())
      .then((response) => {
        setMealComp(response.result);
        // setMealList(response.result);
        // var myheaders = new Headers();
        // myheaders.append("Content-Type", "application/json");
        // let data = {
        //   db_name: process.env.REACT_APP_DB,
        //   entity: process.env.REACT_APP_ENITIY_MEALSCHEDULE,
        //   filter: `${process.env.REACT_APP_ENITIY_MEALSCHEDULE}.MealCode=='${response.result[0]._id}'&& ${process.env.REACT_APP_ENITIY_MEALSCHEDULE}.activestatus==true`,
        //   return_fields: process.env.REACT_APP_ENITIY_MEALSCHEDULE,
        // };
        // var requestOptions = {
        //   method: "POST",
        //   headers: myheaders,
        //   body: JSON.stringify(data),
        //   redirect: "follow",
        // };
        // fetch(
        //   `${process.env.REACT_APP_ARANGO_API}/api/read_documents`,
        //   requestOptions
        // )
        //   .then((result) => result.json())
        //   .then((res) => console.log("mealschedule", res.result[0]));
      })
      .catch((err) => console.error(err));
  }, [dispatch, handleEditFunc]);
  const downloadfile = (fileid) => {
    // var axios = require("axios");
    debugger;
    var config = {
      method: "get",
      url: "https://fileupload.dev.ainqaplatform.in/v1/downloadfile",
      headers: {
        dbname: "ipmo",
        fileID: `${fileid}`,
      },
    };

    axios(config)
      .then(function (response) {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  // const downloadfile = async (fileid) => {
  //   debugger;
  //         // var axios = require('axios');
  //     // var data = {
  //     //   "dbname": "ipmo",
  //     //   "fileID": `${fileid}`
  //     // };

  //     var config = {
  //       method: 'get',
  //       url: 'https://fileupload.dev.ainqaplatform.in/v1/downloadfile',
  //       headers: {
  //         'Content-Type': 'application/json',
  //         'dbname': "ipmo",
  //         'fileID': `${fileid}`
  //       },
  //       //data : data
  //     };

  //     await axios(config)

  //     .then(function (response) {
  //       debugger;
  //       // console.log(JSON.stringify(response.data));
  //       //return JSON.stringify(response.data);
  //       console.log( response.data);
  //       return response.data;
  //     })
  //     .catch(function (error) {
  //       console.log(error);
  //     });

  // };
  const handleMealSchedule = () => {
    // console.log("called", value);
    var myheaders = new Headers();
    myheaders.append("Content-Type", "application/json");
    let data = {
      db_name: process.env.REACT_APP_DB,
      entity: process.env.REACT_APP_ENITIY_MEALSCHEDULE,
      filter: `${process.env.REACT_APP_ENITIY_MEALSCHEDULE}.PatientCategoryCode=='${dropDown.current?._id}'&& ${process.env.REACT_APP_ENITIY_MEALSCHEDULE}.MealDay=='${dropDownForDay.current?._id}' && ${process.env.REACT_APP_ENITIY_MEALSCHEDULE}.activestatus==true`,
      return_fields: process.env.REACT_APP_ENITIY_MEALSCHEDULE,
    };
    var requestOptions = {
      method: "POST",
      headers: myheaders,
      body: JSON.stringify(data),
      redirect: "follow",
    };
    fetch(
      `${process.env.REACT_APP_ARANGO_API}/api/read_documents`,
      requestOptions
    )
      .then((result) => result.json())
      .then((res) => {
        let finalList = res.result.flatMap((e) =>
          mealComp.filter((v) => v._id === e.MealCode && v)
        );
        // console.log(finalList);
        setMealList(finalList);
        // setMealList(res.result);
      })
      .catch((err) => console.error(err));
  };

  return (
    <div style={{ padding: "8px" }}>
      <Paper elevation={0} style={{ padding: "10px" }}>
        <Grid container>
          <Grid
            container
            item
            lg={12}
            md={12}
            sm={12}
            xs={12}
            justifyContent="space-between"
          >
            <Grid item xs={4} sm={3} md={3} lg={3}>
              <Typography gutterBottom className={classes.textResponsive}>
                {"Diet Type"}
              </Typography>
              <Autocomplete
                // id={`${parent_id}-Entity-Type-autocomplete`}
                className={classes.autoCompleteText}
                options={patientCategorys ?? []}
                getOptionLabel={(option) => option.display ?? []}
                size={"small"}
                PaperComponent={({ children }) => (
                  <Paper className={classes.textResponsive}>{children}</Paper>
                )}
                value={
                  patientCategorys.find(
                    (l) => l._id === dropDown?.current?._id
                  ) || ""
                }
                onChange={(e, newvalue) => {
                  dropDown.current = newvalue;
                  handleMealSchedule(newvalue);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    placeholder={"Select Category"}
                    autoComplete="off"
                    size="small"
                  />
                )}
              />
            </Grid>
            <Grid item xs={4} sm={3} md={3} lg={3}>
              <Typography gutterBottom className={classes.textResponsive}>
                {"Day"}
              </Typography>
              <Autocomplete
                // id={`${parent_id}-Entity-Type-autocomplete`}
                className={classes.autoCompleteText}
                options={dayListOrder() ?? []}
                getOptionLabel={(option) => option.display ?? []}
                size={"small"}
                value={
                  daylist.find((l) => l._id === dropDownForDay?.current?._id) ||
                  ""
                }
                PaperComponent={({ children }) => (
                  <Paper className={classes.textResponsive}>{children}</Paper>
                )}
                onChange={(e, newvalue) => {
                  dropDownForDay.current = newvalue;
                  handleMealSchedule(newvalue);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    placeholder={"Select Category"}
                    autoComplete="off"
                    size="small"
                  />
                )}
              />
            </Grid>
            <Grid
              container
              item
              lg={4}
              sm={4}
              md={4}
              xs={3}
              justifyContent="flex-end"
              alignContent="center"
            >
              <Button
                variant="contained"
                color="primary"
                className={classes.textResponsive}
                size={"small"}
                onClick={() => {
                  setOpenDrawer(true);
                  sendEditData.current = {};
                  setEditValue(false);
                }}
              >
                + Add New
              </Button>
            </Grid>
          </Grid>
          <Grid item lg={12} sm={12} md={12} xs={12}>
            {mealList.length !== 0 && (
              <Tabs
                className={classes.tabText}
                value={value}
                onChange={handleChange}
                indicatorColor="primary"
                textColor="primary"
                variant="scrollable"
                scrollButtons="auto"
              >
                {mealCatergoryFunc().map((e) => (
                  <Tab label={e.display} {...a11yProps(0)} />
                ))}
                {/* <Tab label="BreakFast" {...a11yProps(0)} />
                <Tab label="Lunch" {...a11yProps(1)} />
                <Tab label="Dinner" {...a11yProps(2)} /> */}
              </Tabs>
            )}
          </Grid>
          {/* {mealList?.map((e, i) =>
            mealCatergoryFunc().map((mealType, mealindex) =>
              (e.MealCategoryCode === mealType?._id) === true
                ? console.log(e)
                : console.log("ccc", mealindex)
            )
          )} */}
          <Grid container>
            <Grid
              container
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              style={{
                padding: "0",
              }}
            >
              {mealList?.map((e, i) =>
                mealCatergoryFunc().map(
                  (mealType, mealindex) =>
                    (e.MealCategoryCode === mealType?._id) === true &&
                    value === mealindex && (
                      <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                        <Card
                          className={(classes.textResponsive, classes.root)}
                          style={{ margin: "10px", height: "90%" }}
                        >
                          {e.MealPic?.length !== 0 ? (
                            <div
                              style={{
                                height: "100px",
                                display: "block",
                                backgroundSize: "cover",
                                backgroundRepeat: "no-repeat",
                                backgroundPosition: "center",
                                backgroundImage: `url(${process.env.REACT_APP_IMAGE_UPLOAD_PROTOCAL}://${process.env.REACT_APP_IMAGE_UPLOAD_HOST}/${process.env.REACT_APP_DB}/${e?.MealPic})`,
                              }}
                            >
                              {/* <img
                                src={downloadfile(e?.MealPic)}
                                // src={`https://fileupload.dev.ainqaplatform.in/ipmo/${l.MealCode.MealPic}`}
                                alt="icon"
                                style={{
                                  width: "100%",
                                  height: "100%",
                                  // background: "#e0e0e0",
                                  borderRadius: "8px",
                                  objectFit: "cover",
                                }}
                              /> */}
                            </div>
                          ) : (
                            <div
                              style={{
                                height: "100px",
                                display: "block",
                                backgroundSize: "cover",
                                backgroundRepeat: "no-repeat",
                                backgroundPosition: "center",
                                backgroundImage:
                                  "url(images/icons/FoodImage.webp)",
                              }}
                            ></div>
                          )}
                          <CardContent className={classes.content}>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                              }}
                            >
                              <Typography
                                className={classes.textResponsive}
                                gutterBottom
                                variant="h5"
                                component="h2"
                                style={{
                                  fontSize: "20px",
                                  wordWrap: "break-word",
                                  fontWeight: "bold",
                                }}
                              >
                                {e.MealDisplayName}
                              </Typography>
                              <IconButton
                                size="small"
                                className={classes.iconSize}
                                onClick={() => handleEditFunc(e._id)}
                              >
                                <EditIcon className={classes.iconSize} />
                              </IconButton>
                            </div>
                            <Typography
                              className={classes.textResponsive}
                              variant="body2"
                              color="textSecondary"
                              component="p"
                              style={{
                                fontSize: "10px",
                                wordWrap: "break-word",
                                color: "black",
                                // display: "-webkit-box",
                                // WebkitLineClamp: "2",
                                // WebkitBoxOrient: "vertical",
                                // overflow: "hidden",
                              }}
                            >
                              {parse(e.MealDescription)}
                            </Typography>

                            {e.Addon?.map((v, i) => (
                              <div>
                                <Typography
                                  className={classes.textResponsive}
                                  gutterBottom
                                  variant="h5"
                                  component="h2"
                                  style={{ color: "black", fontWeight: "bold" }}
                                >
                                  {v?.title}
                                </Typography>
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <Typography
                                    className={classes.textResponsive}
                                    variant="caption"
                                    color="textSecondary"
                                    component="p"
                                    style={{ color: "black" }}
                                  >
                                    {v.dropdowntext.length !== 0
                                      ? v.dropdowntext
                                      : "-"}
                                  </Typography>

                                  {v.dropdowntext.length !== 0 && (
                                    <div
                                      style={{
                                        margin: "0 10px",
                                        display: "flex",
                                        justifyContent: "flex-start",
                                      }}
                                    >
                                      <Typography
                                        className={classes.textResponsive}
                                        variant="body2"
                                        color="textSecondary"
                                        component="p"
                                        style={{
                                          color: "black",
                                          fontWeight: "bold",
                                        }}
                                      >
                                        multiselect:
                                      </Typography>
                                      <Typography
                                        className={classes.textResponsive}
                                        variant="body2"
                                        color="textSecondary"
                                        component="p"
                                        style={{ color: "black" }}
                                      >
                                        {v.dropdowntext.length === 0
                                          ? "-"
                                          : v?.multiselect
                                          ? "True"
                                          : "False"}
                                      </Typography>
                                    </div>
                                  )}
                                </div>
                                {e?.Addon.length !== i + 1 && (
                                  <div>
                                    <Divider />
                                  </div>
                                )}
                              </div>
                            ))}
                          </CardContent>
                        </Card>
                      </Grid>
                    )
                )
              )}
            </Grid>
          </Grid>
        </Grid>
      </Paper>

      <Dialog
        fullWidth
        maxWidth={"md"}
        aria-labelledby="customized-dialog-title"
        open={openDrawer}
        onClose={() => handleDrawerClose()}
      >
        <OrderForm
          edit={editValue}
          editData={sendEditData.current}
          handleDrawerClose={handleDrawerClose}
        />
      </Dialog>
    </div>
  );
};

export default OrderConfig;
