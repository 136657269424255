import { Box, Grid, TextField, Typography, Button } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import React from "react";
import { actions } from "ipmo_v_dbinder";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { AxiosCall } from "../../api";
import DateFnsUtils from "@date-io/date-fns";
// import { ToastMessage } from "../../utils";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import { RequiredField } from "../../components";
// import { todaydate } from "../../utils/helperFunctions";
import { AlertProps } from "../../utils/";
import { AlertContext, BackdropContext } from "../../contexts";
import Config from "../../config";
export const RegistrationForm = (props) => {
  const {
    FormCompleted,
    isEdit = false,
    editData = null,
    isTransfer = false,
    listCheck,
    // Person,
    showAddNew,
    onTransferCompleted,
  } = props;
  // console.log("registration", editData, isEdit);
  const dispatch = useDispatch();

  const patientCategorys = useSelector(
    (state) => state?.personSlice.patientcategory?.data
  );
  const patientConsistency = useSelector(
    (state) => state?.personSlice.patientconsistency?.data
  );
  // console.log(patientConsistency);
  const genderCategorys = useSelector(
    (state) => state?.personSlice?.gender_master?.data
  );
  const organizationSlice = useSelector(
    (state) => state?.personSlice.orgname?.data
  );
  const EntityType = useSelector(
    (state) => state?.personSlice.facilityList?.data
  );
  const patientsubtype = useSelector(
    (state) => state?.personSlice.patientsubtype?.data
  );
  // console.log("isedit", isEdit);
  const [ward, setWard] = React.useState([]);
  const [room, setRoom] = React.useState([]);
  const [bed, setBed] = React.useState();
  const [hasSubType, setHasSubType] = React.useState(false);
  // const [org, setOrg] = React.useState([]);
  // const [fac, setFac] = React.useState([]);
  const alert = React.useContext(AlertContext);
  const backDrop = React.useContext(BackdropContext);
  const [patientDetails, setPatientDetails] = React.useState(
    isEdit || isTransfer
      ? editData
      : {
          PatientFName: "",
          PatientMName: "",
          PatientLName: "",
          gender: "",
          dob: null,
          age: 0,
          idType: "",
          idNumber: "",
          PatientMRN: "",
          PatientCategory: "",
          PatientMobNumber: "",
          // patitenCheckin:{},
          ward: "",
          room: "",
          bed: "",
          org:
            organizationSlice.length !== 0 && isEdit === false
              ? organizationSlice?.[0]?.id
              : "",
          fac: "",
          Consistency: "",
          Allergies: "",
          FoodPreferences: "",
          PatientCategorysubtype: [],
        }
  );
  // console.log("patientDetails", patientDetails);

  // console.log("Entity", EntityType);
  // const [orgDetails, setOrgDetails] = React.useState({});
  // const [facilityDetails, setFacilityDetails] = React.useState({});
  // const getOrganizationDetails = (OrgId) => {
  //   // console.log("orgid", OrgId);
  //   var myHeaders = new Headers();
  //   myHeaders.append("Content-Type", "application/json");

  //   var raw = JSON.stringify({
  //     db_name: process.env.REACT_APP_DB,
  //     entity: process.env.REACT_APP_ENTITY_ORGANIZATION,
  //     filter: `${process.env.REACT_APP_ENTITY_ORGANIZATION}._id=='${OrgId}'`,
  //     return_fields: process.env.REACT_APP_ENTITY_ORGANIZATION,
  //   });

  //   var requestOptions = {
  //     method: "POST",
  //     headers: myHeaders,
  //     body: raw,
  //     redirect: "follow",
  //   };

  //   fetch(process.env.REACT_APP_ARANGO_URL_READ, requestOptions)
  //     .then((response) => response.json())
  //     .then((result) => setOrgDetails(result.result[0]))
  //     .catch((error) => console.log("error", error));
  // };
  // const getFacilityDetails = (facId) => {
  //   var myHeaders = new Headers();
  //   myHeaders.append("Content-Type", "application/json");

  //   var raw = JSON.stringify({
  //     db_name: process.env.REACT_APP_DB,
  //     entity: process.env.REACT_APP_ENTITY_FACILITY,
  //     filter: `${process.env.REACT_APP_ENTITY_FACILITY}._id=='${facId}'`,
  //     return_fields: process.env.REACT_APP_ENTITY_FACILITY,
  //   });

  //   var requestOptions = {
  //     method: "POST",
  //     headers: myHeaders,
  //     body: raw,
  //     redirect: "follow",
  //   };

  //   fetch(process.env.REACT_APP_ARANGO_URL_READ, requestOptions)
  //     .then((response) => response.json())
  //     .then((result) => {
  //       setFacilityDetails(result.result[0]);
  //       getOrganizationDetails(result.result[0]?.OrgCode);
  //     })
  //     .catch((error) => console.log("error", error));
  // };
  React.useLayoutEffect(() => {
    dispatch(actions.PATIENTCATEGORY());
    dispatch(actions.PATIENTCONSISTENCY());
    dispatch(actions.PATIENTCATEGORYSUBTYPE());
    dispatch(actions.GENDERMASTER());
    dispatch(actions.ORGNAME());
    var qwert = editData;
    var dataShow = editData?.PatientMapping;
    // console.log("patientDetails", editData, qwert);
    var patientcheckin = [];

    if (editData?._id) {
      patientcheckin = dispatch(
        actions.SEARCH_PATIENTCHECKIN(editData?.PatientMapping?._id)
      );
      dispatch(
        actions.GET_WARD_ROOMS({
          key: patientcheckin?.payload?.data[0]?.wardCode,
        })
      );
    }
    // console.log("rrom", room);
    // console.log("dataShow", dataShow);
    let temp = {
      ...patientDetails,
      _keypatientCheckin: dataShow ? dataShow?._id : "",
      room: dataShow ? dataShow?.roomCode : "",
      ward: dataShow ? dataShow?.wardCode : "",
      bed: dataShow ? dataShow?.BedCode : "",
      checkintime: dataShow ? dataShow?.CheckInDateTime : "",
      patientcheckinStatus: dataShow ? dataShow?.Status : "",
      checkinpatientCode: dataShow ? dataShow?.PatientCode : "",
      org: dataShow ? dataShow?.orgCode : organizationSlice?.[0]?.id,
      fac: dataShow ? dataShow?.facCode : "",
    };
    // console.log("temp1", temp);
    temp = {
      ...temp,
      ...qwert,
    };
    // console.log("aaabb", temp);
    // let FacCode = Person[0]?.orgType;
    dispatch(actions.FACILITYLIST(temp?.org));
    setPatientDetails(temp);
    getFacilityWard(temp?.fac);
    getWardRoom("onload", temp?.ward);
    getRoomBed("onload", temp?.room, temp?.ward, temp?.fac);
  }, []);

  React.useEffect(() => {
    // if (showAddNew && isEdit === false)
    if (!showAddNew)
      setPatientDetails({
        PatientFName: "",
        PatientMName: "",
        PatientLName: "",
        gender: "",
        dob: null,
        age: 0,
        idType: "",
        idNumber: "",
        PatientMRN: "",
        PatientCategory: "",
        PatientMobNumber: "",
        // patitenCheckin:{},
        ward: "",
        room: "",
        bed: "",
        org:
          organizationSlice.length !== 0 && isEdit === false
            ? organizationSlice?.[0]?.id
            : "",
        fac: "",
        Consistency: "",
        Allergies: "",
        FoodPreferences: "",
        PatientCategorysubtype: [],
      });

    if (isEdit) {
      getPatientCategory(editData?.PatientCategory, false);
    }
    //eslint-disable-next-line
  }, [isEdit, organizationSlice, showAddNew]);
  // React.useEffect(() => {
  //   //eslint-disable-next-line
  // }, []);
  // console.log("wardvalue", ward, patientDetails);
  const getFacilityWard = async (value) => {
    let ward = await dispatch(actions.GET_FACILITY_WARDS({ key: value }));
    // console.log("qawsed", ward);
    // getFacilityDetails(Person[0]?.orgType);
    setWard(ward?.payload?.data);
  };

  const getWardRoom = async (type, value) => {
    // console.log(value);
    let room = await dispatch(actions.GET_WARD_ROOMS({ key: value }));
    setRoom(room?.payload?.data);
    // console.log("cccheck", room);
  };
  // console.log("what is the bed data", bed);
  const getRoomBed = async (type, value) => {
    // if (patientDetails?.bed?.length === 0) {
    //   let bed = await dispatch(
    //     actions.GET_ROOM_BEDS({
    //       room: checkin?.BedCode?.length !== 0 ? patientDetails.room : value,
    //       ward: patientDetails.ward,
    //       faci: "Person[0]?.orgType",
    //     })
    //   );
    //   setBed(bed?.payload?.data);
    // } else {
    // console.log("poi", patientDetails.bed, value);
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify({
        db_name: process.env.REACT_APP_DB,
        entity: process.env.REACT_APP_ENTITY_BED,
        filter: `${process.env.REACT_APP_ENTITY_BED}.RoomCode=='${value}' && ${process.env.REACT_APP_ENTITY_BED}.activestatus==true`,
        return_fields: process.env.REACT_APP_ENTITY_BED,
      }),
      redirect: "follow",
    };
    fetch(process.env.REACT_APP_ARANGO_URL_READ, requestOptions)
      .then((result) => result.json())
      .then((response) => {
        // console.log(editData);
        let tempCheck = listCheck;
        if (editData?.PatientMapping?.BedCode.length !== 0) {
          tempCheck = tempCheck.filter(
            (e) => editData?.PatientMapping?.BedCode !== e && e
          );
        }
        let filterList = response.result.map((e) => tempCheck.includes(e._id));
        let roomList = response.result.filter(
          (e, eindex) => filterList[eindex] === false && e
        );
        setBed(roomList);
        // console.log("mnb", roomList);
      })
      .catch((err) => console.error(err));
    // }
  };
  // console.log("aaaaaa", patientDetails);
  const DobToAge = (date) => {
    if (date !== null) {
      // console.log("date", date);
      let DOB = +new Date(date);
      let age = (Date.now() - DOB) / 31557600000;
      return Math.floor(age);
    }
    // setPatientDetails({ ...patientDetails, age: age });
  };
  const handleChange = (key, value) => {
    if (key === "ward") {
      getWardRoom("onchange", value);
      setPatientDetails({ ...patientDetails, [key]: value, room: "", bed: "" });
    } else if (key === "org") {
      getWardRoom("onchange", value);
      setPatientDetails({
        ...patientDetails,
        [key]: value,
        fac: "",
        ward: "",
        room: "",
        bed: "",
      });
    } else if (key === "fac") {
      getWardRoom("onchange", value);
      setPatientDetails({
        ...patientDetails,
        [key]: value,
        ward: "",
        room: "",
        bed: "",
      });
    } else if (key === "room") {
      getRoomBed("onchange", value, patientDetails.ward);
      setPatientDetails({ ...patientDetails, [key]: value, bed: "" });
    } else if (key === "dob") {
      let currantage = DobToAge(value);
      setPatientDetails({
        ...patientDetails,
        dob: value,
        age: !isNaN(currantage) ? currantage : 0,
      });
    } else if (key === "PatientMobNumber") {
      const re = /^[0-9\b]+$/;
      if (value === "" || re.test(value)) {
        setPatientDetails({ ...patientDetails, [key]: value });
      }
    } else if (key === "Allergies") {
      const re = /^[a-zA-z, ]+$/;
      if (value === "" || re.test(value)) {
        setPatientDetails({ ...patientDetails, [key]: value });
      }
    } else if (key === "FoodPreferences") {
      const re = /^[a-zA-z, ]+$/;
      if (value === "" || re.test(value)) {
        setPatientDetails({ ...patientDetails, [key]: value });
      }
    } else if (key === "PatientCategory") {
      debugger;
      const filterCatetoryHasSubType = patientCategorys?.find(
        (l) => l?._id === value
      );
      let hasType =
        filterCatetoryHasSubType?.gmconfigvalues?.["Sub-Type"] ?? false;
      setHasSubType(hasType);

      setPatientDetails({
        ...patientDetails,
        [key]: value,
        ...(!hasType && { PatientCategorysubtype: [] }),
      });
      // getPatientCategory(value, true,key);
    } else {
      setPatientDetails({ ...patientDetails, [key]: value });
    }
  };

  const getPatientCategory = (value, checkPatient, key) => {
    // debugger;
    const filterCatetoryHasSubType = patientCategorys?.find(
      (l) => l?._id === value
    );
    let hasType =
      filterCatetoryHasSubType?.gmconfigvalues?.["Sub-Type"] ?? false;
    setHasSubType(hasType);
    if (!hasType && checkPatient) {
      setPatientDetails({
        ...patientDetails,
        PatientCategorysubtype: [],
      });
    }
  };

  // const handleChangeDob = (Date) => {
  //   // console.log(Date);
  //   let currantage = DobToAge(Date);
  //   setPatientDetails({ ...patientDetails, dob: Date, age: currantage });
  // };

  // const genderCategory = [
  //   { label: "Male" },
  //   { label: "Felmale" },
  //   { label: "Other" },
  // ];

  const idTypeList = [{ label: "Passport" }, { label: "NRIC" }];
  // console.log("patient validation", patientDetails);

  const checkMRN = async (PatientMRN) => {
    let params = {
      db_name: Config.dbname,
      entity: "Patient",
      // sort: "document(CodeableConceptMaster.coding[0]).display",
      filter: `Patient.PatientMRN == '${PatientMRN}'`,
      return_fields: "Patient",
    };
    let res = await AxiosCall(
      "post",
      `${Config.api_url}/api/read_documents`,
      params
    );
    let result = res?.result;

    return result;
  };
  const isFormCompleted = async () => {
    // debugger;
    let Valid = {
      PatientCategory: patientDetails.PatientCategory,
      room: patientDetails.room,
      ward: patientDetails.ward,
      bed: patientDetails.bed,
      org: patientDetails.org,
      fac: patientDetails.fac,
      PatientFName: patientDetails.PatientFName,
      PatientLName: patientDetails.PatientLName,
      gender: patientDetails.gender,
      dob: patientDetails.dob,
      age: patientDetails.age.toString().includes("-")
        ? ""
        : patientDetails.age.toString(),
      consistency: patientDetails.Consistency,
      allergies: patientDetails.Allergies,
      // idType: patientDetails.idType,
      // idNumber: patientDetails.idNumber,
      PatientMRN: patientDetails.PatientMRN,
      ...(hasSubType && {
        PatientCategorysubtype: patientDetails?.PatientCategorysubtype,
      }),
      // PatientMobNumber: patientDetails.PatientMobNumber,
    };
    if (Object.values(Valid).every((e) => e !== undefined && e.length !== 0)) {
      let isMRN = await checkMRN(patientDetails.PatientMRN);
      if (!isEdit && isMRN?.length > 0) {
        alert.setSnack({
          open: true,
          severity: AlertProps.severity.error,
          msg: "MRN already exists!",
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.center,
        });
      } else {
        backDrop.setBackDrop({
          ...backDrop,
          open: true,
          message: `Please Wait...`,
        });
        let res = await dispatch(
          actions.PATIENT_MAPPING({ _data: patientDetails })
        );
        if (res?.payload?.error) {
          alert.setSnack({
            open: true,
            severity: AlertProps.severity.error,
            msg: res?.payload?.message ?? "Somethind went wrong!",
            vertical: AlertProps.vertical.top,
            horizontal: AlertProps.horizontal.center,
          });
        } else {
          alert.setSnack({
            open: true,
            severity: AlertProps.severity.success,
            msg: patientDetails?._keypatientCheckin
              ? "Updated Successfully!"
              : "Checked-In Successfully!",
            vertical: AlertProps.vertical.top,
            horizontal: AlertProps.horizontal.center,
          });
          FormCompleted();
        }
        backDrop.setBackDrop({
          ...backDrop,
          open: false,
          message: ``,
        });
      }
    } else {
      alert.setSnack({
        open: true,
        severity: AlertProps.severity.error,
        msg: "Please Fill The Required Fields",
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.center,
      });
    }
    // console.log("r", res);
  };
  // const handleCheckOut = async () => {
  //   let _res = await resetPasswordToDefault(patientDetails?.bed);
  //   // console.log(_res);
  //   if (_res.Code === "201") {
  //     var myHeaders = new Headers();
  //     myHeaders.append("Content-Type", "application/json");
  //     var requestOptions = {
  //       method: "POST",
  //       headers: myHeaders,
  //       body: JSON.stringify([
  //         {
  //           db_name: process.env.REACT_APP_DB,
  //           entity: process.env.REACT_APP_ENTITY_PATIENTCHECKIN,
  //           filter: { _id: patientDetails?._keypatientCheckin },
  //           doc: {
  //             BedCode: patientDetails?.bed,
  //             CheckInDateTime: patientDetails.checkintime,
  //             PatientCode: patientDetails.PatientCode,
  //             Status: "CodingMaster/11308",
  //             CheckOutDateTime: Date.parse(new Date()) / 1000,
  //             roomCode: patientDetails.room,
  //             wardCode: patientDetails.ward,
  //             facCode: patientDetails.fac,
  //             orgCode: patientDetails.org,
  //             activestatus: false,
  //           },
  //         },
  //       ]),
  //       redirect: "follow",
  //     };
  //     await fetch(process.env.REACT_APP_ARANGO_URL_UPSERT, requestOptions)
  //       .then((result) => result.json())
  //       .then((response) =>
  //         alert.setSnack({
  //           open: true,
  //           severity: AlertProps.severity.success,
  //           msg: "Checked-In Successfully",
  //           vertical: AlertProps.vertical.top,
  //           horizontal: AlertProps.horizontal.right,
  //         })
  //       )
  //       .catch((err) => console.error(err));
  //     FormCompleted();
  //   } else {
  //     ToastMessage("Unable to Check-Out", "error");
  //   }
  // };
  const getParent = async (type) => {
    //this method call the facility list
    dispatch(actions.FACILITYLIST(type));
    // let res = await dispatch(actions.FACILITYLIST(type));
    // setFac(res.payload.data);
    // console.log("getParent", res.payload.data);
    // console.log("parent", parent);
  };
  const handleOrgChange = (value) => {
    // setOrg(value);
    getParent(value?.id);
  };

  // const resetPasswordToDefault = async (bedid) => {
  //   // debugger;
  //   let selectedBed = bed.find((l) => l._id === bedid);
  //   let params = {
  //     email: selectedBed?.BedNumber,
  //     password: "adminpassword",
  //   };
  //     let header = {tenantid:process.env.REACT_APP_IDM_TENANT_ID};
  //   let res = await AxiosCall("post", process.env.REACT_APP_KCLK_RESET, params,header);
  //   return res;
  // };

  const isTransferCompleted = async () => {
    // debugger;
    let Valid = {
      room: patientDetails.room,
      ward: patientDetails.ward,
      bed: patientDetails.bed,
      org: patientDetails.org,
      fac: patientDetails.fac,
    };
    if (
      Object.values(Valid).every(
        (e) => e !== undefined && e.trim().length !== 0
      )
    ) {
      if (patientDetails.bed === editData.PatientMapping.BedCode) {
        alert.setSnack({
          open: true,
          severity: AlertProps.severity.error,
          msg: "Cannot transfer to same bed",
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.center,
        });
      } else {
        backDrop.setBackDrop({
          ...backDrop,
          open: true,
          message: `Transferring bed...`,
        });
        //step1 checkout patient  and resetpassword
        let params = [
          {
            db_name: Config.dbname,
            entity: "PatientCheckIn",
            // sort: "document(CodeableConceptMaster.coding[0]).display",
            filter: { _key: editData?.PatientMapping?._key },
            doc: {
              CheckOutDateTime: Date.parse(new Date()) / 1000,
              activestatus: false,
              Status: process.env.REACT_APP_PATIENTCHECKIN_STATUS_CHECKOUT,
            },
          },
        ];

        let res = await AxiosCall(
          "post",
          `${Config.api_url}/api/upsert_document`,
          params
        );

        if (res?.Result?.length > 0) {
          //reset password to default
          await CheckInWithBedCode(
            false,
            "adminpassword",
            editData?.PatientMapping?.BedCode
          );
          //reset bed Count
          await ResetBedCount(editData?.PatientMapping?.BedCode);
          let isUpdated = await checkInToNewBed(patientDetails);
          if (isUpdated) {
            await checkPatientOrderedMeal(
              patientDetails?._id,
              editData.PatientMapping.BedCode,
              patientDetails?.bed
            );
          } else {
            let params = [
              {
                db_name: Config.dbname,
                entity: "PatientCheckIn",
                filter: { _key: editData?.PatientMapping?._key },
                doc: {
                  checkintime: Date.parse(new Date()) / 1000,
                  activestatus: true,
                  Status: process.env.REACT_APP_PATIENTCHECKIN_STATUS_CHECKOUT,
                },
              },
            ];

            let res = await AxiosCall(
              "post",
              `${Config.api_url}/api/upsert_document`,
              params
            );

            if (res?.result.length > 0) {
              CheckInWithBedCode(
                true,
                editData?.PatientLName,
                patientDetails.bed
              );
              backDrop.setBackDrop({
                ...backDrop,
                open: false,
              });
            }
          }
        } else {
          alert.setSnack({
            open: true,
            severity: AlertProps.severity.error,
            msg: "Unable to checkin to new bed",
            vertical: AlertProps.vertical.top,
            horizontal: AlertProps.horizontal.center,
          });
        }
      }
    } else {
      alert.setSnack({
        open: true,
        severity: AlertProps.severity.error,
        msg: "Please Fill The Required Fields",
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.center,
      });
    }
  };

  const ResetBedCount = async (bedCode) => {
    let params = [
      {
        db_name: process.env.REACT_APP_DB,
        entity: process.env.REACT_APP_ENTITY_BED,
        filter: { _id: bedCode },
        doc: {
          LoginCount: 0,
        },
      },
    ];

    let res = await AxiosCall(
      "post",
      `${Config.api_url}/api/upsert_document`,
      params
    );
    let data = res?.Result;
    return data;
  };

  const checkInToNewBed = async (patientDetails) => {
    // debugger;
    let params = [
      {
        db_name: Config.dbname,
        entity: "PatientCheckIn",
        doc: {
          PatientCode: patientDetails?._id,
          BedCode: patientDetails?.bed,
          roomCode: patientDetails?.room,
          wardCode: patientDetails?.ward,
          Status: process.env.REACT_APP_CODING_MASTER_PATIENT_CHECKIN_STATUS_ID,
          CheckInDateTime: Date.parse(new Date()) / 1000,
          CheckOutDateTime: 0,
          orgCode: patientDetails?.org,
          facCode: patientDetails?.fac,
        },
      },
    ];
    let res = await AxiosCall(
      "post",
      `${Config.api_url}/api/upsert_document`,
      params
    );
    let data = res?.Result;
    if (data?.length > 0) {
      await CheckInWithBedCode(
        true,
        patientDetails?.PatientLName,
        patientDetails.bed
      );
      return true;
    } else {
      alert.setSnack({
        open: true,
        severity: AlertProps.severity.error,
        msg: "Unable to map with new bed",
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.right,
      });
      return false;
    }
  };

  const CheckInWithBedCode = async (isCheckIn, lastName, bedCode) => {
    try {
      let params = {
        db_name: Config.dbname,
        entity: "Bed",
        filter: `Bed._id=='${bedCode}' && Bed.activestatus==true`,
        return_fields: "Bed.BedNumber",
      };
      let _res = await AxiosCall(
        "post",
        `${Config.api_url}/api/read_documents`,
        params
      );
      let user = _res.result[0];

      let password = isCheckIn ? `${user}${lastName.toLowerCase()}` : lastName;

      let reset = resetPassword(true, user, password);

      return reset;
    } catch (error) {
      return error;
    }

    // return res;
  };

  const resetPassword = async (CheckIn, user, password) => {
    let params = {
      email: user,
      password: CheckIn ? password : "adminpassword",
    };
    let header = { tenantid: process.env.REACT_APP_IDM_TENANT_ID };
    let res = await AxiosCall(
      "post",
      process.env.REACT_APP_KCLK_RESET,
      params,
      header
    );
    return res;
  };

  let checkPatientOrderedMeal = async (PatientCode, BedCode, newBed) => {
    let params = {
      db_name: Config.dbname,
      entity: "MealOrder",
      filter: `MealOrder.PatientCode=='${PatientCode}' && MealOrder.PatientBedNumber=='${BedCode}' && MealOrder.activestatus==true`,
      return_fields: "MealOrder",
    };
    let res = await AxiosCall(
      "post",
      `${Config.api_url}/api/read_documents`,
      params
    );
    let data = res?.result;

    if (data?.length > 0) {
      let result = await data?.map(async (l) => {
        let params = [
          {
            db_name: Config.dbname,
            entity: "MealOrder",
            filter: { _key: l?._key },
            doc: {
              PatientBedNumber: newBed,
            },
          },
        ];
        let res = await AxiosCall(
          "post",
          `${Config.api_url}/api/upsert_document`,
          params
        );
        let data = res?.Result;
        if (data?.length > 0) {
          let getLogData = await getLogByticketId(l?.ticketId);
          let newbedorgdetail = await getNewBedOrgDetails(newBed);
          // let updateQDMlog =
          await updateMealQDMlog(getLogData, newbedorgdetail);
        }
      });
      if (result.length === data?.length) {
        onTransferCompleted();
        backDrop.setBackDrop({
          ...backDrop,
          open: false,
        });
        alert.setSnack({
          open: true,
          severity: AlertProps.severity.succes,
          msg: "Transfer completed successfully",
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.center,
        });
      }
    } else {
      onTransferCompleted();
      backDrop.setBackDrop({
        ...backDrop,
        open: false,
      });
      alert.setSnack({
        open: true,
        severity: AlertProps.severity.succes,
        msg: "Transfer completed successfully",
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.center,
      });
    }
  };

  const getLogByticketId = async (ticketId) => {
    let params = {
      db_name: `${Config.dbname}`,
      entity: "QDMATPtransactionlog",
      filter: `QDMATPtransactionlog.ticketId=='${ticketId}' && QDMATPtransactionlog.activestatus==true `,
      return_fields: "QDMATPtransactionlog",
    };
    let res = await AxiosCall(
      "post",
      `${Config.api_url}/api/read_documents`,
      params
    );
    let data = res.result[0];
    return data;
  };

  const getNewBedOrgDetails = async (BedCode) => {
    let params = {
      db_name: "ipmo",
      entity: "Bed",
      filter: `Bed._id=='${BedCode}'&& Bed.activestatus == true`,
      return_fields:
        "{BedCode:UNSET(Bed,'_key','_rev','activestatus','createdby','createddate','updatedate','updatedby','gmconfigvalues'),RoomCode:UNSET(document(Bed.RoomCode),'_key','_rev','activestatus','createdby','createddate','updatedate','updatedby','gmconfigvalues'),WardCode:UNSET(document(document(Bed.RoomCode).WardCode),'_key','_rev','activestatus','createdby','createddate','updatedate','updatedby','gmconfigvalues'),FacCode:UNSET(document(document(document(Bed.RoomCode).WardCode).FacCode),'_key','_rev','activestatus','createdby','createddate','updatedate','updatedby','gmconfigvalues'),OrgCode:UNSET(document(document(document(document(Bed.RoomCode).WardCode).FacCode).OrgCode),'_key','_rev','activestatus','createdby','createddate','updatedate','updatedby','gmconfigvalues')}",
    };
    let res = await AxiosCall(
      "post",
      `${Config.api_url}/api/read_documents`,
      params
    );
    let data = res.result[0];
    return data;
  };

  const updateMealQDMlog = async (oldLogdata, newOrgData) => {
    // debugger;
    let updatedPayload = {
      ...oldLogdata?.payload,
      inputDoc: {
        ...oldLogdata?.payload?.inputDoc,
        PatientBedNumber: newOrgData?.BedCode,
        FacCode: newOrgData?.FacCode,
        OrgCode: newOrgData?.OrgCode,
        WardCode: newOrgData?.WardCode,
        RoomCode: newOrgData?.RoomCode,
      },
    };
    let params = [
      {
        db_name: Config.dbname,
        entity: "QDMATPtransactionlog",
        filter: { _key: oldLogdata?._key },
        doc: {
          payload: updatedPayload,
        },
      },
    ];
    let res = await AxiosCall(
      "post",
      `${Config.api_url}/api/upsert_document`,
      params
    );
    let data = res.Result[0];
    return data;
  };
  return (
    <>
      <Grid container>
        <Grid container item xs={12} sm={12} md={7} lg={7}>
          <Box
            style={{
              padding: "16px",
              width: "100%",
              overflowY: "auto",
              background: "#fff",
              borderRadius: "8px",
              margin: "8px",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Grid container item xs={12} sm={12} md={12} lg={12} spacing={1}>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Typography variant="body1" fullWidth>
                  {"Patient Details"}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <Typography variant="body2" gutterBottom>
                  {"First Name"}
                  <RequiredField color={"red"} />
                </Typography>
                <TextField
                  id="outlined-basic"
                  variant="outlined"
                  fullWidth
                  disabled={isTransfer}
                  autoComplete="off"
                  size="small"
                  placeholder={"First Name"}
                  value={patientDetails.PatientFName}
                  onChange={(e) => handleChange("PatientFName", e.target.value)}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <Typography variant="body2" gutterBottom>
                  {"Middle Name"}
                </Typography>
                <TextField
                  id="outlined-basic"
                  variant="outlined"
                  fullWidth
                  autoComplete="off"
                  disabled={isTransfer}
                  size="small"
                  placeholder={"Middle Name"}
                  value={patientDetails.PatientMName}
                  onChange={(e) => handleChange("PatientMName", e.target.value)}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <Typography variant="body2" gutterBottom>
                  {"Last Name"}
                  <RequiredField color={"red"} />
                </Typography>
                <TextField
                  id="outlined-basic"
                  variant="outlined"
                  fullWidth
                  disabled={isTransfer}
                  autoComplete="off"
                  size="small"
                  placeholder={"Last Name"}
                  value={patientDetails.PatientLName}
                  onChange={(e) => handleChange("PatientLName", e.target.value)}
                  // value={searchValue}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <Typography variant="body2" gutterBottom>
                  {"Gender"}
                  <RequiredField color={"red"} />
                </Typography>
                <Autocomplete
                  // id={`${parent_id}-Entity-Type-autocomplete`}
                  options={genderCategorys ?? []}
                  getOptionLabel={(option) => option.display || ""}
                  size={"small"}
                  value={
                    genderCategorys.find(
                      (l) => l._id === patientDetails.gender
                    ) || ""
                  }
                  disabled={isTransfer}
                  onChange={(e, value) => handleChange("gender", value?._id)}
                  renderInput={(params) => (
                    <TextField
                      // id={`${parent_id}-Entity-Type-textField`}
                      {...params}
                      variant="outlined"
                      // error={props.data?.error?.EntityType}
                      placeholder={"Select"}
                      autoComplete="off"
                      size="small"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <Typography variant="body2" gutterBottom>
                  {"DOB"}
                  <RequiredField color={"red"} />
                </Typography>

                {/* <MuiPickersUtilsProvider utils={DateFnsUtils}> */}
                <Grid container justifyContent="space-around">
                  {/* <TextField
                    id="outlined-basic"
                    variant="outlined"
                    fullWidth
                    autoComplete="off"
                    size="small"
                    type="date"
                    InputProps={{
                      inputProps: { min: "1900-12-01", max: todaydate() },
                    }}
                    // placeholder={"Last Name"}
                    value={patientDetails?.dob}
                    onChange={(e) => handleChange("dob", e.target.value)}
                    // value={searchValue}
                  /> */}
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      disableFuture={true}
                      maxDateMessage="Future Date Not Available"
                      fullWidth
                      size="small"
                      value={patientDetails?.dob}
                      placeholder="dd-mm-yyyy"
                      onChange={(e) => handleChange("dob", e)}
                      maxDate={new Date()}
                      format="dd/MM/yyyy"
                      autoOk
                      disabled={isTransfer}
                      variant="inline"
                      inputVariant="outlined"
                      InputAdornmentProps={{ position: "start" }}
                    />
                  </MuiPickersUtilsProvider>
                  {/* <DatePicker
                      disableToolbar
                      inputVariant="outlined"
                      format="dd/MM/yyyy"
                      openTo="year"
                      id="date-picker-inline"
                      views={["date", "month", "year"]}
                      value={patientDetails?.dob ? patientDetails?.dob : null}
                      size="small"
                      onChange={(Date) => handleChangeDob(Date)}
                      animateYearScrolling
                      // variant="dialog"
                      autoOk
                      disableFuture
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                    /> */}
                  {/* <KeyboardDatePicker
                      // margin="normal"
                      id="date-picker-dialog"
                      // label="Date picker dialog"
                      inputVariant="outlined"
                      format="dd/MM/yyyy"
                      size="small"
                      value={patientDetails?.dob ? patientDetails?.dob : null}
                      onChange={(Date) => handleChangeDob(Date)}
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                    /> */}
                </Grid>
                {/* </MuiPickersUtilsProvider> */}
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <Typography variant="body2" gutterBottom>
                  {"Age"}
                </Typography>
                <TextField
                  id="outlined-basic"
                  variant="outlined"
                  fullWidth
                  autoComplete="off"
                  size="small"
                  placeholder={"Age"}
                  disabled
                  value={patientDetails.age}
                  onChange={(e) => handleChange("age", e.target.value)}
                />
              </Grid>

              <Grid item xs={12} sm={12} md={4} lg={4}>
                <Typography variant="body2" gutterBottom>
                  {"Mobile Number"}
                  {/* <RequiredField color={"red"} /> */}
                </Typography>
                <TextField
                  id="outlined-basic"
                  variant="outlined"
                  fullWidth
                  autoComplete="off"
                  size="small"
                  disabled={isTransfer}
                  placeholder={"Mobile Number"}
                  value={patientDetails?.PatientMobNumber}
                  inputProps={{ maxLength: 10 }}
                  onChange={(e) =>
                    handleChange("PatientMobNumber", e.target.value)
                  }
                  // value={searchValue}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <Typography gutterBottom variant="body2">
                  {"Diet Type"}
                  <RequiredField color={"red"} />
                </Typography>
                <Autocomplete
                  // id={`${parent_id}-Entity-Type-autocomplete`}
                  options={patientCategorys ?? []}
                  getOptionLabel={(option) => option.display || ""}
                  size={"small"}
                  disabled={isTransfer}
                  value={
                    patientCategorys?.find(
                      (l) => l?._id === patientDetails?.PatientCategory
                    ) || ""
                  }
                  onChange={(e, value) =>
                    handleChange("PatientCategory", value?._id)
                  }
                  renderInput={(params) => (
                    <TextField
                      // id={`${parent_id}-Entity-Type-textField`}
                      {...params}
                      variant="outlined"
                      // error={props.data?.error?.EntityType}
                      placeholder={"Select Category"}
                      autoComplete="off"
                      size="small"
                    />
                  )}
                />
              </Grid>
              {hasSubType && (
                <Grid item xs={12} sm={12} md={4} lg={4}>
                  <Typography gutterBottom variant="body2">
                    {"Diet Sub Type"} <RequiredField color={"red"} />
                  </Typography>
                  <Autocomplete multiple
                    // id={`${parent_id}-Entity-Type-autocomplete`}
                    options={patientsubtype ?? []}
                    getOptionLabel={(option) => option.display || ""}
                    size={"small"}
                    disabled={isTransfer}
                    value={
                      /*patientsubtype?.find(
                        (l) => l?._id === patientDetails?.PatientCategorysubtype
                      ) || ""*/
                      patientDetails?.PatientCategorysubtype
                    }
                    onChange={(e, value) =>
                      handleChange("PatientCategorysubtype", value)
                    }
                    renderInput={(params) => (
                      <TextField
                        // id={`${parent_id}-Entity-Type-textField`}
                        {...params}
                        variant="outlined"
                        // error={props.data?.error?.EntityType}
                        placeholder={"Select Category"}
                        autoComplete="off"
                        size="small"
                      />
                    )}
                  />
                </Grid>
              )}

              <Grid item xs={12} sm={12} md={4} lg={4}>
                <Typography variant="body2" gutterBottom>
                  {"Patient MRN"}
                  <RequiredField color={"red"} />
                </Typography>
                <TextField
                  id="outlined-basic"
                  variant="outlined"
                  fullWidth
                  autoComplete="off"
                  disabled={isEdit || isTransfer ? true : false}
                  size="small"
                  placeholder={"MRN"}
                  value={patientDetails.PatientMRN}
                  onChange={(e) => handleChange("PatientMRN", e.target.value)}
                  // value={searchValue}
                />
              </Grid>

              <Grid item xs={12} sm={12} md={4} lg={4}>
                <Typography variant="body2" gutterBottom>
                  {"Consistency"} <RequiredField color={"red"} />
                </Typography>
                <Autocomplete
                  // id={`${parent_id}-Entity-Type-autocomplete`}
                  options={patientConsistency ?? []}
                  getOptionLabel={(option) => option.display || ""}
                  size={"small"}
                  disabled={isTransfer}
                  value={
                    patientConsistency?.find(
                      (l) => l?._id === patientDetails?.Consistency
                    ) || ""
                  }
                  onChange={(e, value) =>
                    handleChange("Consistency", value?._id)
                  }
                  renderInput={(params) => (
                    <TextField
                      // id={`${parent_id}-Entity-Type-textField`}
                      {...params}
                      variant="outlined"
                      // error={props.data?.error?.EntityType}
                      placeholder={"Select Consistency"}
                      autoComplete="off"
                      size="small"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <Typography gutterBottom variant="body2">
                  {"Allergies"} <RequiredField color={"red"} />
                </Typography>
                <TextField
                  id="outlined-basic"
                  variant="outlined"
                  fullWidth
                  autoComplete="off"
                  size="small"
                  disabled={isTransfer}
                  placeholder={"Allergies"}
                  value={patientDetails?.Allergies}
                  onChange={(e) => handleChange("Allergies", e.target.value)}
                  // value={searchValue}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <Typography variant="body2" gutterBottom>
                  {"Food Preferences"}
                </Typography>
                <TextField
                  id="outlined-basic"
                  variant="outlined"
                  fullWidth
                  autoComplete="off"
                  size="small"
                  disabled={isTransfer}
                  placeholder={"Food Preferences"}
                  value={patientDetails?.FoodPreferences}
                  onChange={(e) =>
                    handleChange("FoodPreferences", e.target.value)
                  }
                  // value={searchValue}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={hasSubType ? 3 : 4}>
                <Typography variant="body2" gutterBottom>
                  {"Id Type"}
                  {/* <RequiredField color={"red"} /> */}
                </Typography>
                <Autocomplete
                  // id={`${parent_id}-Entity-Type-autocomplete`}
                  options={idTypeList}
                  getOptionLabel={(option) => option.label || ""}
                  size={"small"}
                  disabled={isTransfer}
                  value={idTypeList.find(
                    (l) => l.label === patientDetails?.idType
                  )}
                  onChange={(e, value) => handleChange("idType", value?.label)}
                  renderInput={(params) => (
                    <TextField
                      // id={`${parent_id}-Entity-Type-textField`}
                      {...params}
                      variant="outlined"
                      // error={props.data?.error?.EntityType}
                      placeholder={"Id Type"}
                      autoComplete="off"
                      size="small"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={8} lg={hasSubType ? 5 : 8}>
                <Typography variant="body2" gutterBottom>
                  {"Id Card No"}
                  {/* <RequiredField color={"red"} /> */}
                </Typography>
                <TextField
                  id="outlined-basic"
                  variant="outlined"
                  fullWidth
                  disabled={isTransfer}
                  autoComplete="off"
                  size="small"
                  placeholder={"Enter Id Number"}
                  value={patientDetails.idNumber}
                  onChange={(e) => handleChange("idNumber", e.target.value)}
                  // value={searchValue}
                />
              </Grid>
              {/* <Grid container item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Grid item xs={12} sm={12} md={4} lg={4}>
                  <Typography variant="body2" gutterBottom>
                    {"Id Type"}
                  </Typography>
                  <Autocomplete
                    // id={`${parent_id}-Entity-Type-autocomplete`}
                    options={idTypeList}
                    getOptionLabel={(option) => option.label || ""}
                    size={"small"}
                    value={idTypeList.find(
                      (l) => l.label === patientDetails?.idType
                    )}
                    onChange={(e, value) =>
                      handleChange("idType", value?.label)
                    }
                    renderInput={(params) => (
                      <TextField
                        // id={`${parent_id}-Entity-Type-textField`}
                        {...params}
                        variant="outlined"
                        // error={props.data?.error?.EntityType}
                        placeholder={"Id Type"}
                        autoComplete="off"
                        size="small"
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={8} lg={8}>
                  <Typography variant="body2" gutterBottom>
                    {"Id Card No"}
                  </Typography>
                  <TextField
                    id="outlined-basic"
                    variant="outlined"
                    fullWidth
                    autoComplete="off"
                    size="small"
                    placeholder={"Enter Id Number"}
                    value={patientDetails.idNumber}
                    onChange={(e) => handleChange("idNumber", e.target.value)}
                    // value={searchValue}
                  />
                </Grid>
              </Grid> */}
            </Grid>
          </Box>
        </Grid>
        {/* </Box> */}
        {/* <Box style={{ padding: "16px" }}> */}
        <Grid container item xs={12} sm={12} md={5} lg={5}>
          <Box
            style={{
              padding: "16px",
              width: "100%",
              overflowY: "auto",
              background: "#fff",
              borderRadius: "8px",
              margin: "8px",
              display: "flex",
              justifyContent: "center",
            }}
          >
            {/* {console.log(
              "check this autocomm",
              ward?.find((l) => l.id === patientDetails.ward)
            )} */}
            <Grid container item xs={12} sm={12} md={12} lg={12} spacing={1}>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Typography variant="body1">{"Bed Mapping"}</Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <Typography variant="body2" gutterBottom>
                  {"ORG"}
                  <RequiredField color={"red"} />
                </Typography>
                <Autocomplete
                  // id={`${parent_id}-Entity-Type-autocomplete`}
                  disabled={
                    isTransfer
                      ? false
                      : patientDetails?._keypatientCheckin ||
                        patientDetails.PatientLName.length === 0
                      ? true
                      : false
                  }
                  options={organizationSlice}
                  getOptionLabel={(option) => option.name || ""}
                  value={
                    organizationSlice.find(
                      (l) => l.id === patientDetails.org
                    ) || ""
                  }
                  onChange={(e, value) => {
                    handleOrgChange(value);
                    handleChange("org", value?.id);
                  }}
                  size={"small"}
                  renderInput={(params) => (
                    <TextField
                      // id={`${parent_id}-Entity-Type-textField`}
                      {...params}
                      variant="outlined"
                      // error={props.data?.error?.EntityType}
                      placeholder={"Select Entity"}
                      autoComplete="off"
                      size="small"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <Typography variant="body2" gutterBottom>
                  {"FACILITY"}
                  <RequiredField color={"red"} />
                </Typography>
                <Autocomplete
                  // id={`${parent_id}-Entity-Type-autocomplete`}
                  disabled={
                    isTransfer
                      ? false
                      : patientDetails?._keypatientCheckin ||
                        patientDetails.PatientLName.length === 0
                      ? true
                      : false
                  }
                  options={EntityType ?? []}
                  getOptionLabel={(option) => option.name}
                  value={
                    EntityType.find((l) => l.id === patientDetails.fac) || ""
                  }
                  onChange={(e, value) => {
                    getFacilityWard(value?.id);
                    handleChange("fac", value?.id);
                  }}
                  size={"small"}
                  renderInput={(params) => (
                    <TextField
                      // id={`${parent_id}-Entity-Type-textField`}
                      {...params}
                      variant="outlined"
                      // error={props.data?.error?.EntityType}
                      placeholder={"Entity Name"}
                      autoComplete="off"
                      size="small"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Typography variant="body2" gutterBottom>
                  {"Ward"}
                  <RequiredField color={"red"} />
                </Typography>
                <Autocomplete
                  // id={`${parent_id}-Entity-Type-autocomplete`}
                  options={ward ?? []}
                  getOptionLabel={(option) => option.name || ""}
                  size={"small"}
                  value={ward?.find((l) => l.id === patientDetails.ward) || ""}
                  onChange={(e, value) => handleChange("ward", value?.id)}
                  disabled={
                    isTransfer
                      ? false
                      : patientDetails?._keypatientCheckin ||
                        patientDetails.PatientLName.length === 0
                      ? true
                      : false
                  }
                  renderInput={(params) => (
                    <TextField
                      // id={`${parent_id}-Entity-Type-textField`}
                      {...params}
                      variant="outlined"
                      // error={props.data?.error?.EntityType}
                      placeholder={"Select Ward"}
                      autoComplete="off"
                      size="small"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Typography variant="body2" gutterBottom>
                  {"Room"}
                  <RequiredField color={"red"} />
                </Typography>
                <Autocomplete
                  // id={`${parent_id}-Entity-Type-autocomplete`}
                  options={room}
                  getOptionLabel={(option) => option.name || ""}
                  size={"small"}
                  value={room?.find((l) => l.id === patientDetails.room) || ""}
                  onChange={(e, value) => handleChange("room", value?.id)}
                  disabled={
                    isTransfer
                      ? false
                      : patientDetails?._keypatientCheckin ||
                        patientDetails.PatientLName.length === 0
                      ? true
                      : false
                  }
                  renderInput={(params) => (
                    <TextField
                      // id={`${parent_id}-Entity-Type-textField`}
                      {...params}
                      variant="outlined"
                      // error={props.data?.error?.EntityType}
                      placeholder={"Select Room"}
                      autoComplete="off"
                      size="small"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Typography variant="body2" gutterBottom>
                  {"Bed"}
                  <RequiredField color={"red"} />
                </Typography>
                <Autocomplete
                  // id={`${parent_id}-Entity-Type-autocomplete`}
                  options={bed ?? []}
                  getOptionLabel={(option) => option.BedNumber || ""}
                  size={"small"}
                  value={bed?.find((l) => l._id === patientDetails.bed) || ""}
                  onChange={(e, value) => handleChange("bed", value?._id)}
                  disabled={
                    isTransfer
                      ? false
                      : patientDetails?._keypatientCheckin ||
                        patientDetails.PatientLName.length === 0
                      ? true
                      : false
                  }
                  renderInput={(params) => (
                    <TextField
                      // id={`${parent_id}-Entity-Type-textField`}
                      {...params}
                      variant="outlined"
                      // error={props.data?.error?.EntityType}
                      placeholder={"Select Bed"}
                      autoComplete="off"
                      size="small"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                {isTransfer ? (
                  <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    onClick={() => isTransferCompleted()}
                  >
                    {"BED TRANSFER"}
                  </Button>
                ) : (
                  <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    onClick={() => isFormCompleted()}
                  >
                    {patientDetails?._keypatientCheckin
                      ? "UPDATE "
                      : "CHECK-IN"}
                  </Button>
                )}
              </Grid>
              {/* {console.log("final check out", patientDetails)} */}
              {/* {patientDetails?._keypatientCheckin && (
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Button
                    variant="contained"
                    color="secondary"
                    fullWidth
                    onClick={() => handleCheckOut()}
                  >
                    {"CHECK-OUT"}
                  </Button>
                </Grid>
              )} */}
            </Grid>
          </Box>
        </Grid>
        {/* </Box> */}
      </Grid>
    </>
  );
};
