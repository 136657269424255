import { makeStyles, Paper, Typography } from "@material-ui/core";
import React from "react";

const useStyles = makeStyles((theme) => ({
  textResponsive: {
    fontWeight: "600",
    [theme.breakpoints.up("md")]: {
      fontSize: "2.2rem !important",
    },
    [theme.breakpoints.down("md")]: {
      fontSize: "2.2rem !important",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "2rem !important",
    },
  },
}));

const Home = () => {
  const classes = useStyles();
  return (
    <>
      <Paper
        style={{
          width: "100%",
          height: "100%",
        }}
        elevation={3}
      >
        <Paper
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          elevation={3}
        >
          <Typography
            variant="h3"
            align="center"
            className={classes.textResponsive}
          >
            Welcome to <br />
            In-Patient Meal Order
          </Typography>
        </Paper>
      </Paper>
    </>
  );
};

export default Home;
