import React from "react";
import {
  alpha,
  Box,
  Button,
  Chip,
  DialogActions,
  Grid,
  IconButton,
  makeStyles,
  // Paper,
  TextareaAutosize,
  // TextField,
  Typography,
  useMediaQuery,
  useTheme,
  // withStyles,
} from "@material-ui/core";
// import PropTypes from "prop-types";
import ClearRoundedIcon from "@material-ui/icons/ClearRounded";
// import Rating from "@material-ui/lab/Rating";
// import SentimentVeryDissatisfiedIcon from "@material-ui/icons/SentimentVeryDissatisfied";
// import SentimentDissatisfiedIcon from "@material-ui/icons/SentimentDissatisfied";
// import SentimentSatisfiedIcon from "@material-ui/icons/SentimentSatisfied";
// import SentimentSatisfiedAltIcon from "@material-ui/icons/SentimentSatisfiedAltOutlined";
// import SentimentVerySatisfiedIcon from "@material-ui/icons/SentimentVerySatisfied";
import { useDispatch } from "react-redux";
import { actions } from "ipmo_v_dbinder";
import { RateUsComponent } from "../../components";
import { AlertProps } from "../../utils";
import { AlertContext } from "../../contexts";
// import FastfoodIcon from "@material-ui/icons/Fastfood";
// import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import parse from "html-react-parser";

const useStyles = makeStyles((theme) => ({
  Header: {
    display: "flex",
    // justifyContent: "space-between",
    alignItems: "center",
    padding: theme.spacing(2),
    borderBottom: "1px solid #dbdbdb",
    background: theme.palette.background.table,
  },
  textResponsive: {
    fontWeight: "600",
    flex: "1 1 auto",
    fontSize: "1.1rem",
    [theme.breakpoints.down("md")]: {
      fontSize: "1rem !important",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "1rem !important",
    },
  },
  content: {
    height: "calc(100% - 104px)",
    padding: "8px",
    overflowY: "auto",
    // background: theme.palette.background.main,
  },
  footer: {
    borderTop: "1px solid #dbdbdb",
    paddingBottom: theme.spacing(2),
    paddingTop: theme.spacing(2),
  },
  flexBox: {
    display: "flex",
    alignItems: "center",
    marginBottom: theme.spacing(1),
  },
  title: {
    flex: "1 1 auto",
    marginLeft: theme.spacing(1),
  },
  group: {
    marginLeft: "8px !important",
    paddingLeft: 12,
    borderLeft: `1px dashed ${alpha(theme.palette.text.primary, 0.4)}`,
  },
  numberTag: {
    height: "20px",
    display: "flex",
    padding: "0 6px",
    flexWrap: "wrap",
    fontSize: "0.8rem",
    minWidth: "20px",
    boxSizing: "border-box",
    transition: "transform 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    alignItems: "center",
    fontFamily: "IPMO_regular",
    fontWeight: "500",
    lineHeight: "1",
    alignContent: "center",
    borderRadius: "10px",
    flexDirection: "row",
    justifyContent: "center",
    background: theme.palette.primary.main,
    color: theme.palette.common.white,
    marginRight: "8px",
  },
  // smileIcon: {
  //   margin: "4px",
  //   // color: theme.palette.primary.main,
  //   [theme.breakpoints.down("sm")]: {
  //     margin: "2px",
  //   },
  // },
}));

// const customIcons = {
//   1: {
//     icon: <SentimentVeryDissatisfiedIcon fontSize="large" />,
//     label: "Very Dissatisfied",
//     color: "error",
//   },
//   2: {
//     icon: <SentimentDissatisfiedIcon fontSize="large" />,
//     label: "Dissatisfied",
//     color: "error",
//   },
//   3: {
//     icon: <SentimentSatisfiedIcon fontSize="large" />,
//     label: "Neutral",
//     color: "warning",
//   },
//   4: {
//     icon: <SentimentSatisfiedAltIcon fontSize="large" />,
//     label: "Satisfied",
//     color: "success",
//   },
//   5: {
//     icon: <SentimentVerySatisfiedIcon fontSize="large" />,
//     label: "Very Satisfied",
//     color: "success",
//   },
// };

// const StyledRating = withStyles((theme) => ({
//   iconFilled: {
//     color: theme.palette["primary"].main,
//   },
//   iconHover: {
//     color: theme.palette["primary"].main,
//   },
// }))(Rating);

// function IconContainer(props) {
//   const { value, ...other } = props;
//   const classes = useStyles();

//   return (
//     <span {...other} style={{ margin: "4px 2px" }}>
//       {customIcons[value].icon}
//     </span>
//   );
// }

export const RatingForm = (props) => {
  const classes = useStyles();
  const {
    selectItem,
    ratingItems,
    user,
    ratingMaster,
    setRatingItems,
    handleClose,
  } = props;
  const dispatch = useDispatch();
  const alert = React.useContext(AlertContext);
  const Theme = useTheme();
  const forPhone = useMediaQuery(Theme.breakpoints.down("sm"));

  const [value, setValue] = React.useState([]);

  const getSelectedRating = (value, index) => {
    let temp = [...ratingItems];
    let displayLabel = ratingMaster[value - 1]?.coding?.display;
    let CodingmasterID = ratingMaster[value - 1]?.coding?._id;

    temp[index]["rating"] = {
      value: value,
      label: displayLabel,
      ratingMaster: CodingmasterID,
    };
    setRatingItems(temp);
  };

  const getHoverRating = (e, i) => {
    let displayLabel = ratingMaster[e - 1]?.coding?.display;
    let temp = [...ratingMaster];
    temp[i]["hoverValue"] = e;
    temp[i]["hoverLabel"] = displayLabel;
    setValue(temp);
  };

  const handleChange = (index, key, value) => {
    let temp = [...ratingItems];
    temp[index][key] = value;
    setRatingItems(temp);
  };

  const Valid = (ratingItems) => {
    let checkHasValue = ratingItems?.map((l) => {
      let ratingObj = l?.rating?.label.trim().length > 0;
      if (l.feedback.trim().length > 0 || ratingObj) {
        return true;
      } else {
        return false;
      }
    });

    let reTurn = checkHasValue?.includes(true);

    return reTurn;
  };

  const submitFeedback = async () => {
    // debugger;
    let valid = Valid(ratingItems);

    if (valid) {
      let params = {
        Mealorder_id: selectItem?._id,
        Person_id: user?.PatientCode,
        Feedback: ratingItems,
        ...(selectItem?.isEdit && { _key: selectItem?.feedBack[0]?._key }),
      };
      let res = await dispatch(actions.FEEDBACK_FORM_UPSERT({ state: params }));

      if (res.payload.data.length > 0) {
        handleClose(true);

        alert.setSnack({
          open: true,
          severity: AlertProps.severity.success,
          msg: selectItem?.isEdit
            ? "Thank you for updating feedback."
            : "Thank you for reaching out and providing us with valuable feedback.",
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.center,
        });
      } else {
        alert.setSnack({
          open: true,
          severity: AlertProps.severity.error,
          msg: selectItem?.isEdit
            ? "nable to update yout feedback,Try again later"
            : "Unable to Submit yout feedback,Try again laster",
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.center,
        });
      }
    } else {
      alert.setSnack({
        open: true,
        severity: AlertProps.severity.error,
        msg: "Required Fields Are Empty",
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.center,
      });
    }
  };

  return (
    <>
      <Box className={classes.Header}>
        <Typography variant="h6" className={classes.textResponsive}>
          {"Rate Your Experience"}
        </Typography>
        {forPhone ? (
          <IconButton onClick={handleClose}>
            <ClearRoundedIcon />
          </IconButton>
        ) : (
          <Button
            onClick={handleClose}
            variant="contained"
            color="primary"
            // startIcon={<KeyboardArrowDownIcon />}
          >
            {"Close"}
          </Button>
        )}
      </Box>
      <Box className={classes.content}>
        <Grid container spacing={2}>
          {ratingItems?.map((l, i) => (
            <Grid item xs={12} lg={12} sm={12} md={12} key={`${i}`}>
              {/* <Paper style={{ padding: "16px" }}> */}
              <Box className={classes.flexBox}>
                {/* <FastfoodIcon fontSize="small" /> */}
                <Box className={classes.numberTag}>{i + 1}</Box>
                <Typography
                  variant="body1"
                  // display="Block"
                  className={classes.title}
                >
                  {parse(l.title)}
                </Typography>
              </Box>

              <Grid
                item
                xs={12}
                lg={12}
                sm={12}
                md={12}
                className={classes.group}
              >
                <Typography
                  variant="body2"
                  // display="Block"
                  // className={classes.textResponsive}
                  style={{ marginLeft: "8px" }}
                  gutterBottom
                >
                  {"Did you like the taste?"}
                </Typography>
                {/* Rating */}
                <Box style={{ display: "flex", alignItems: "center" }}>
                  {/* <StyledRating
                    name={`customized-color-${i}`}
                    value={l?.rating?.value}
                    max={ratingMaster?.length}
                    onChange={(event, newValue) =>
                      getSelectedRating(newValue, i)
                    }
                    onChangeActive={(event, newHover) =>
                      getHoverRating(newHover, i)
                    }
                    IconContainerComponent={IconContainer}
                  /> */}
                  <RateUsComponent
                    id={i}
                    value={l?.rating?.value}
                    hoverValue={value[i]?.hoverValue}
                    max={ratingMaster?.length}
                    getSelectedRating={getSelectedRating}
                    getHoverRating={getHoverRating}
                    // IconContainerComponent={IconContainer}
                  />
                  {l?.rating?.label && (
                    // <Typography
                    //   variant="body1"
                    //   display="Block"
                    //   // color="textSecondary"
                    //   className={classes.textResponsive}
                    //   gutterBottom
                    // >
                    //   {l?.rating?.label}
                    // </Typography>
                    <Chip
                      label={l?.rating?.label}
                      color={"primary"}
                      size={forPhone ? "small" : "medium"}

                      // style={{ marginLeft: "16px" }}
                    />
                  )}
                  {!l?.rating?.label && value[i]?.hoverLabel && (
                    <Chip
                      label={value[i]?.hoverLabel}
                      color={"primary"}
                      size={forPhone ? "small" : "medium"}
                      // style={{ marginLeft: "16px" }}
                    />
                  )}
                </Box>

                {/* <TextField
                    id="outlined-basic"
                    variant="outlined"
                    fullWidth
                    autoComplete="off"
                    size="small"
                    placeholder={`Write the review for ${l.title}`}
                    value={l?.feedback}
                    onChange={(e) =>
                      handleChange(i, "feedback", e.target.value)
                    }
                  /> */}
                <TextareaAutosize
                  aria-label="minimum height"
                  value={l?.feedback}
                  onChange={(e) => handleChange(i, "feedback", e.target.value)}
                  className={classes.textArea}
                  placeholder={`Write your review`}
                  style={{
                    width: "100%",
                    borderColor: "#b3b3b3",
                    marginTop: "8px",
                    borderRadius: "5px",
                    padding: "8.5px 14px",
                    resize: "vertical",
                    fontFamily: "IPMO_regular",
                  }}
                  minRows={5}
                  maxlength="300"
                  // error={error?.isinputSchema}
                  // helperText={error?.isinputSchema ? "This Field is Required*" : ""}
                />
              </Grid>
              {/* </Paper> */}
            </Grid>
          ))}
        </Grid>
      </Box>
      <DialogActions className={classes.footer}>
        <Button onClick={handleClose} color="primary">
          cancel
        </Button>
        <Button
          onClick={() => submitFeedback()}
          variant="contained"
          color="primary"
        >
          {selectItem?.isEdit ? "Update Feedback" : "Submit Feedback"}
        </Button>
      </DialogActions>
    </>
  );
};
