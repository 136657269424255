import {
  Badge,
  Box,
  Button,
  Grid,
  IconButton,
  Tab,
  Tabs,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import ReactHorizontalDatePicker from "../../components/horizontalCalendar/hori";

import { makeStyles } from "@material-ui/styles";
import React from "react";
import ShoppingCartOutlinedIcon from "@material-ui/icons/ShoppingCartOutlined";
import Dialog from "@material-ui/core/Dialog";
import Slide from "@material-ui/core/Slide";
import { Cart } from "./cart";
import { ListItem } from "./listItem";
import { useSelector } from "react-redux";
import { getTmrwDate } from "../../utils";
import { CartIcon } from "../../assets";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import Config from "../../config";
import { AxiosCall } from "../../api";
import { FeedBack } from "./feedback";
// import QuestionAnswerIcon from "@material-ui/icons/QuestionAnswer";
import WarningIcon from "@material-ui/icons/Warning";
import UpdateIcon from "@material-ui/icons/Update";
const useStyles = makeStyles((theme) => ({
  content: {
    width: "100%",
    height: "100%",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "8px 16px",
    background: theme.palette.background.table,
    height: "80px",
    borderBottom: " 1px solid #e0e0e0",
    [theme.breakpoints.down("md")]: {
      padding: "4px 8px",
    },
  },
  cartIconContainer: {
    position: "relative",
    display: "flex",
    cursor: "pointer",
    marginLeft: "8px",
    flex: "0 0 auto",
  },
  badge: {
    fontWeight: "600",
    position: "absolute",
    top: "-10px",
    left: "10px",
    // paddingBottom: "3px",
    background: "#007AFF",
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    alignContent: "center",
    color: "#fff",
    boxSizing: "border-box",
    fontSize: "1rem",
    height: "20px",
    minWidth: "20px",
    transform: "fontSize 225ms cubic-bezier(0.4, 0, 0.2, 1)",
  },
  textResponsive: {
    // fontSize: "1.1rem !important",

    [theme.breakpoints.up("md")]: {
      fontSize: "1rem !important",
    },
    [theme.breakpoints.down("md")]: {
      fontSize: "0.8rem !important",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.7rem !important",
    },
  },

  TabsRoot: {
    flexGrow: 1,
    boxShadow: "0px 10px 25px #0000000A",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    background: theme.palette.background.table,
    borderBottom: " 1px solid #e0e0e0",
    "& .App1-MuiTabs-root": {
      "& .App1-MuiTab-root": {
        padding: "0px",
      },
      "& .App1-MuiTabs-flexContainer": {
        alignItems: "center",
        justifyContent: "space-between",
        margin: "0px 17px",
        padding: "0px",
      },
      "& .App1-MuiTabs-indicator": {
        display: "block",
        backgroundColor: "#0071F2",
      },
      "& .Mui-selected": {
        backgroundColor: "transparent",
        color: "inherit",
      },
    },
  },
  Tabs: {
    color: theme.palette.text.primary,
    ".MuiTab-textColorPrimary": {
      color: theme.palette.text.primary,
    },
  },
  TabPanel: {
    padding: "16px",
    height: "calc(100% - 130px)",
    overflow: "auto",
    background: theme.palette.background.table,
  },
  disclaimerBox: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "8px 16px",
    borderRadius: "8px",
    background: theme.palette.background.main,
    flex: "1 1 auto",
    [theme.breakpoints.down("md")]: {
      padding: "0px 8px",
      borderRadius: "unset",
    },
  },
}));

function TabPanel(props) {
  const classes = useStyles();

  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      className={classes.TabPanel}
      {...other}
    >
      {value === index && (
        <Box style={{ height: "100%" }}>{props.children}</Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
}

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const OrderCatalog = (props) => {
  const {
    user,
    getMealSchedule,
    date,
    fromKitchen,
    backToSearch,
    Count,
    countLimit,
    handleTour,
  } = props;
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const [tablist, setTablist] = React.useState([]);
  const [hideTour, setHideTour] = React.useState(true);
  const tmrw = React.useRef(null);
  const menuList = useSelector(
    (state) => state?.orderCatalogSlice?.getMealSchedule
  );
  const Theme = useTheme();
  const forPhone = useMediaQuery(Theme.breakpoints.up("sm"));
  const forTab = useMediaQuery(Theme.breakpoints.down("md"));

  const [feedBackDialog, setFeedBackDialog] = React.useState(false);

  const handleOpenDialog = () => {
    setFeedBackDialog(true);
  };

  // console.log("menuList", menuList);

  const handleCloseDialog = () => {
    setFeedBackDialog(false);
  };
  React.useEffect(() => {
    let tmrwDate = getTmrwDate();
    tmrw.current = tmrwDate;
    if (date) {
      getMealTypes(date);
    }
    //eslint-disable-next-line
  }, [date]);

  const getMealTypes = async (dateNew) => {
    let params = {
      db_name: Config.dbname,
      entity: "CodeableConceptMaster",
      filter:
        "CodeableConceptMaster.Type=='MEALTYPE' &&CodeableConceptMaster.status==true && CodeableConceptMaster.activestatus==true",
      sort: "CodeableConceptMaster.text",
      return_fields:
        "MERGE(CodeableConceptMaster,{coding:FIRST(FOR cod IN CodeableConceptMaster.coding RETURN DOCUMENT(cod))})",
    };
    let res = await AxiosCall(
      "post",
      `${Config.api_url}/api/read_documents`,
      params
    );

    let result = res?.result;
    setTablist(result);
    /* This below code we disable the tab menu if cusoff time over */
    // if (fromKitchen) {
    //   let updateTab = result?.map((l, index) =>
    //     Object.assign(l, {
    //       disable: false,
    //     })
    //   );
    //   setTablist(updateTab);
    // }
    // else {
    //   let updateTab = result?.map((l, index) =>
    //     Object.assign(l, {
    //       disable: getCutoffFlag(
    //         l?.coding?.display,
    //         dateNew,
    //         l?.coding?.gmconfigvalues?.TIME
    //       ),
    //     })
    //   );

    //   let index = updateTab.findIndex((l) => l?.disable === false);
    //   setValue(index);
    //   setTablist(updateTab);
    //   let updateList=result.map(l)
    // }
  };

  /* Add this function for cutoff feature */
  // const getCutoffFlag = (type, dateNew, cutoffvalue) => {
  //   let epoche = dateNew.getDate();
  //   // let curDate = new Date();
  //   if (type === "Breakfast") {
  //     let hrs = dateNew.getHours();
  //     var todaydate = new Date();
  //     if (epoche === new Date().getDate() && type === "Breakfast") {
  //       let flag = true;
  //       return flag;
  //     } else {
  //       let flag = hrs < parseInt(cutoffvalue) ? false : true;
  //       return flag;
  //     }
  //   } else if (type === "Lunch" && epoche === new Date().getDate()) {
  //     let hrs = dateNew.getHours();

  //     let flag = hrs < parseInt(cutoffvalue) ? false : true;
  //     return flag;
  //   } else if (type === "Dinner" && epoche === new Date().getDate()) {
  //     let hrs = dateNew.getHours();

  //     let flag = hrs < parseInt(cutoffvalue) ? false : true;
  //     return flag;
  //   } else if (
  //     type === "Afternoon Tea Menu" &&
  //     epoche === new Date().getDate()
  //   ) {
  //     let hrs = dateNew.getHours();
  //     let flag = hrs < parseInt(cutoffvalue) ? false : true;
  //     return flag;
  //   } else {
  //     return false;
  //   }
  // };

  const [cartList, setCartlist] = React.useState([]);

  const [selectedAddon, setSelectedAddon] = React.useState([]);

  const [openDialog, setDialog] = React.useState(false);
  const updateCartState = (value) => {
    // debugger;
    let getExistMealOnCategory = cartList.find(
      (l) =>
        l?.MealDay === value?.MealDay && l?.MealSchedule === value?.MealSchedule
    );
    let updatedList = cartList.filter(
      (l) => getExistMealOnCategory?.MealCode?._id !== l?.MealCode?._id
    );
    setCartlist([...updatedList, value]);
    let updateAddon = selectedAddon.filter(
      (a) => a?.MealCode?._id !== getExistMealOnCategory?.MealCode?._id
    );
    setSelectedAddon(updateAddon);
  };

  const handleOpen = () => {
    setDialog(true);
  };

  const handleClose = () => {
    setDialog(false);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const removeFromCart = (data) => {
    let updatedList = cartList.filter(
      (l) => l?.MealCode?._id !== data?.MealCode?._id
    );
    let updateAddon = selectedAddon.filter(
      (a) => a?.MealCode?._id !== data?.MealCode?._id
    );
    setCartlist(updatedList);
    setSelectedAddon(updateAddon);
  };
  const isAddedToCart = (data) => {
    // debugger;
    let isAdded =
      cartList.filter((l) => l?.MealCode?._id === data?.MealCode?._id).length >
      0
        ? true
        : false;
    return isAdded;
  };

  const selectedDay = (val) => {
    getMealSchedule(val);
    // setCartlist([]);
  };

  const updateHideTour = () => {
    setHideTour(false);
  };

  return (
    <>
      <Box className={classes.content}>
        {!fromKitchen && user?.Disclaimer && forTab && (
          <Box className={classes.disclaimerBox}>
            <IconButton>
              <WarningIcon sx={{ color: "red" }} />
            </IconButton>
            <Typography
              color="primary"
              variant="body2"
              className={classes?.textResponsive}
            >
              {user?.Disclaimer}
            </Typography>
          </Box>
        )}
        <Grid className={classes.header}>
          {fromKitchen && (
            <Button
              color="primary"
              onClick={() => backToSearch()}
              startIcon={<ArrowBackIcon fontSize="small" />}
              style={{
                flex: "0 0 auto",
              }}
            >
              {"Back"}
            </Button>
          )}
          {/* <Grid
            item
            xs={12}
            lg={3}
            sm={12}
            md={3}
            style={
              fromKitchen
                ? {
                    display: "flex",
                    justifyContent: "center",
                    textAlign: "center",
                    zIndex: "2",
                  }
                : { textAlign: "center", zIndex: "2" }
            }
            data_tour="step_1"
          > */}
          <div
            data_tour="step_1"
            style={{
              flex: "0 0 auto",
            }}
          >
            <ReactHorizontalDatePicker
              selectedDay={selectedDay}
              enableScroll={true}
              enableDays={3}
              disabledDays={[new Date(2017, 12, 31), new Date(2017, 12, 16)]}
            />
          </div>

          {/* </Grid> */}
          {!fromKitchen && user?.Disclaimer && !forTab && (
            <Box className={classes.disclaimerBox}>
              <IconButton>
                <WarningIcon style={{ color: "red" }} />
              </IconButton>
              <Typography
                color="primary"
                // variant="body2"
                className={classes?.textResponsive}
              >
                {user?.Disclaimer}
              </Typography>
            </Box>
          )}

          {forPhone && (
            <div
              data_tour="step_5"
              className={classes.cartIconContainer}
              onClick={() => handleOpen()}
            >
              <span className={classes.badge}>{cartList?.length}</span>
              <CartIcon fontSize="1rem" width="35px" height="35px" />
              <Typography
                variant="h6"
                color="primary"
                style={{ fontWeight: "600" }}
              >
                {"Cart"}
              </Typography>
            </div>
          )}
        </Grid>
        <Grid item xs={12} lg={12} sm={12} md={12}>
          <div className={classes.TabsRoot}>
            <Tabs
              value={value}
              onChange={handleChange}
              indicatorColor="primary"
              textColor="primary"
              variant="scrollable"
              scrollButtons="auto"
              aria-label="scrollable auto tabs example"
              data_tour="step_2"
            >
              {tablist?.map((l, index) => (
                <Tab
                  label={l?.coding?.display}
                  key={`${index}`}
                  className={classes.Tabs}
                  {...a11yProps(index)}
                />
              ))}
            </Tabs>
            <div style={{ display: "flex", alignItems: "center" }}>
              {!forPhone && (
                <IconButton data_tour="step_5" onClick={() => handleOpen()}>
                  <Badge badgeContent={cartList?.length} color="primary">
                    <ShoppingCartOutlinedIcon fontSize="small" />
                  </Badge>
                </IconButton>
              )}
              {!forPhone ? (
                <Tooltip title="Order History" arrow>
                  <IconButton
                    color="primary"
                    onClick={() => handleOpenDialog()}
                  >
                    <UpdateIcon />
                  </IconButton>
                </Tooltip>
              ) : (
                <Button
                  variant="text"
                  color="primary"
                  startIcon={<UpdateIcon />}
                  onClick={() => handleOpenDialog()}
                >
                  {"Order Histroy"}
                </Button>
              )}
            </div>
          </div>
        </Grid>

        {tablist?.map((tab, i) => (
          <TabPanel value={value} key={`${i}`} index={i}>
            <ListItem
              type={tab?.coding?.display}
              codingMasterId={tab?.coding?._id}
              // type={tab?.coding?.display}
              updateCartState={updateCartState}
              menuList={menuList?.data}
              selectedAddon={selectedAddon}
              fromKitchen={fromKitchen}
              setSelectedAddon={setSelectedAddon}
              isAddedToCart={isAddedToCart}
              removeFromCart={removeFromCart}
              value={0}
              Count={Count}
              tabNum={i}
              user={user}
              countLimit={countLimit}
              date={date}
              hideTour={hideTour}
              handleTour={handleTour}
              updateHideTour={updateHideTour}
              cutOff={tab?.coding?.gmconfigvalues?.TIME}
            />
          </TabPanel>
        ))}
        <Dialog
          fullScreen
          open={openDialog}
          onClose={handleClose}
          TransitionComponent={Transition}
        >
          <Cart
            handleClose={handleClose}
            removeFromCart={removeFromCart}
            cartList={cartList}
            user={user}
            date={date}
            getMealSchedule={getMealSchedule}
            setCartlist={setCartlist}
            selectedAddon={selectedAddon}
            setSelectedAddon={setSelectedAddon}
          />
        </Dialog>

        <Dialog
          fullScreen
          open={feedBackDialog}
          onClose={handleCloseDialog}
          TransitionComponent={Transition}
        >
          <FeedBack handleCloseDialog={handleCloseDialog} user={user} />
        </Dialog>
      </Box>
    </>
  );
};

export default OrderCatalog;
