import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { Routes } from "./routes";

import {
  NotFound,
  Login,
  Organization,
  TaskAndDocument,
  PatientOrdering,
  GeneralMasterIPMO,
  PatientRegistration,
  TaskViewers,
  OrderConfig,
  KitchenOrdering,
  ExposeApi,
  Home,
} from "./../screens";
import Repository from "../screens/repository";
import PractitionerMaster from "../screens/practitionerMaster";
import { Drawer } from "../components";
import Reports from "../screens/reportViewer/report";
import { repolist } from "../utils";

const RouterApp = (props) => {
  return (
    <Router>
      <Switch>
        {/* Login Route */}

        <Route exact path={Routes.login}>
          <Login />
        </Route>

        <Route exact path={Routes.home}>
          <Drawer variant="2" sidePanellist={repolist}>
            <Home />
          </Drawer>
        </Route>

        {/* Organization Route */}

        <Route exact path={Routes.Organization}>
          <Drawer variant="2" sidePanellist={repolist}>
            <Organization />
          </Drawer>
        </Route>

        {/* IDM management route */}
        <Route exact path={Routes.repositry}>
          <Drawer variant="2" sidePanellist={repolist}>
            <Repository />
          </Drawer>
        </Route>

        {/*  */}
        <Route exact path={Routes.practitioner}>
          <Drawer variant="2" sidePanellist={repolist}>
            <PractitionerMaster />
          </Drawer>
        </Route>

        {/* Task And Document */}
        <Route exact path={Routes.taskdocument}>
          <Drawer variant="2" sidePanellist={repolist}>
            <TaskAndDocument />
          </Drawer>
        </Route>

        {/* Report Viewer */}
        <Route exact path={Routes.reportConfig}>
          <Drawer variant="2" sidePanellist={repolist}>
            <Reports />
          </Drawer>
        </Route>

        {/* Meal Ordering */}
        <Route exact path={Routes.menu}>
          <Drawer variant="2" sidePanellist={repolist}>
            <PatientOrdering />
          </Drawer>
        </Route>

        {/* Genaral Master */}
        <Route exact path={Routes.generalMaster}>
          <Drawer variant="2" sidePanellist={repolist}>
            <GeneralMasterIPMO />
          </Drawer>
        </Route>

        {/* Visit Resgistration */}
        <Route exact path={Routes.patientRegistration}>
          <Drawer variant="2" sidePanellist={repolist}>
            <PatientRegistration />
          </Drawer>
        </Route>

        {/* Task Viewer */}
        <Route exact path={Routes.taskViewer}>
          <Drawer variant="2" sidePanellist={repolist}>
            <TaskViewers />
          </Drawer>
        </Route>

        {/* Order Configurator */}
        <Route exact path={Routes.orderConfigurator}>
          <Drawer variant="2" sidePanellist={repolist}>
            <OrderConfig />
          </Drawer>
        </Route>

        {/* Kitchen Meal Ordering */}
        <Route exact path={Routes.kitchenMenu}>
          <Drawer variant="2" sidePanellist={repolist}>
            <KitchenOrdering />
          </Drawer>
        </Route>

        {/* For unknow/non-defined path */}
        <Route exact path="/updateMealOrderStatus" component={ExposeApi} />

        <Route exact path="*" component={NotFound} />
      </Switch>
    </Router>
  );
};

export default RouterApp;
